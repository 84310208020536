import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    refreshHandlerFunc,
    CenterNameFunc,
    webBotTimeOutFunc,
    setWebsocketConnectionStatus

} from "../../../ReduxStore/action";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import parse from "html-react-parser";

import styles from "./AskConfirmation.module.css";
import LanguageStore from "../../../data/LanguageStore";
const AskConfirmation = (props) => {
    const { language_selection_text, rebookAppointmentText,iswebSocketTryingToReConnected } = useSelector((state) => state.Reducer);
    const { iswebSocketConnected } = useSelector(state => state.Reducer)

    const dispatch = useDispatch();

    const handleBtnEvent = (option,index) => {
        let message = {
            event: option.event,
            uid: props.conversationId,
            data: option.text,
          };
        if (index === 0) {
            if (iswebSocketConnected == "DISCONNECTED") {
                dispatch(refreshHandlerFunc(false));
                props.actionProvider.showResponseDialog({
                    type: "RESTART_WITH_NEW_CONNECTION",
                });
            }
            else {
                let message = {
                    event: "CLOSE_CONVERSATION",
                    uid: props.conversationId,
                };
                if (props.captureEventType != "HANG_UP") {
                    props.wsClient.handleUserResponse(message);
                }
                dispatch(refreshHandlerFunc(false));
                dispatch(setWebsocketConnectionStatus(false))
                props.actionProvider.showResponseDialog({
                    type: "RESTARTCONVERASTION",
                    message: LanguageStore.data.length>1?LanguageStore.getDisplayText() : LanguageStore.welcomeMessage[0]
                   

                });

            }

            dispatch(CenterNameFunc(""));
            dispatch(webBotTimeOutFunc(false));
        } else{
            props.actionProvider.showResponseDialog({
        type: "DENY_RESET_SCAN_DETAILS",
        message: option.text,
         });
       }
       props.wsClient?.handleUserResponse(message, handleAIResponse);
      
    };

 

      const handleAIResponse = (webSocketMessage) => {
        let ai_response = JSON.parse(webSocketMessage.data);
    
        props.actionProvider.showResponseDialog(ai_response);
      };

    

    const NotifiactionText = () => {
        return (
            <div className={styles.vengage__notifiactionText}>
                <p>  {rebookAppointmentText && parse(rebookAppointmentText.message)} </p>
                {/* <p>Please click "<span style={{color:"#0A9EDB"}}>Restart</span>" to choose a different appointment slot.</p> */}
                <p className={styles.vengage__note}>

                    {rebookAppointmentText && parse(rebookAppointmentText.note)}
                </p>
            </div>
        );
    };

    return (
        <div className={styles.vengage__askConfirmation}>
            <div
                className={`${styles.vengage__options} ${styles.vengage__restartPopUp}`}
            >
                <NotifiactionText />
                <div className={styles.vengage__options__container}>
                    {rebookAppointmentText && rebookAppointmentText.preset_responses.map((option,index) =>
                        <CustomButton
                            text={option.text}
                            clickHandler={(e) => handleBtnEvent(option,index)}
                            disabled={iswebSocketTryingToReConnected?iswebSocketTryingToReConnected:props.disableAllButton}
                        />
                    )}



                </div>
            </div>
        </div>
    );
};

export default AskConfirmation;
