import { w3cwebsocket as W3CWebSocket } from "websocket";
import { v4 as uuidv4 } from "uuid";

import {
    CONNECTION_STATUS,
    SERVER_GENERATED_RESPONSE_CODES,
    WS_URL,
} from "../ConstVariable";

let serverResponseTimeDelays = [10000, 30000, 40000, 60000];

class WebSocketConnection {
    constructor(
        webSocketConnectionStateCallBack,
        setShowCountDown,
        setCountDownMessage,
        setReportResponseDelay,
        sendLogToBackend,
        captureTheSentMsgViaBot,
        setSentMsgId,
        lastResponseFromBackend,
        setLastResponseFromBackend,
        updateCountDownTime,
        ws_connection_status,
        setWebsocketTryingToReconnectStatus
    ) {
        this.ws_url = `${WS_URL}/api/v1/chatbot`;
        this.webSocketConnectionStateCallBack = webSocketConnectionStateCallBack;
        this.connection_status=null
        this.setShowCountDown = setShowCountDown;
        this.setCountDownMessage = setCountDownMessage;
        this.setReportResponseDelay = setReportResponseDelay;
        this.sendLogToBackend = sendLogToBackend;
        this.captureTheSentMsg = captureTheSentMsgViaBot;
        this.setSentMsgId = setSentMsgId;
        this.lastResponseFromBackend = lastResponseFromBackend;
        this.setLastResponseFromBackend = setLastResponseFromBackend;
        this.ws_connection_status=ws_connection_status ;
        this.onMessageCallback = null;
        this.updateCountDownTime=updateCountDownTime
        this.timer = null;
        this.showDelayresponseError = false;
        this.conversation_id = null;
        this.intervalId = null;
        this.isMsgCameFromBackend = true;
        this.message_sent_id = null;
        this.lastResponseFromAPI = null;
        this.responseReceivedViaWebSocket = false;
        this.languageCode = "en";
        this.pingTimeout = 15000;
        this.pingTimeInterval = null;
        this.serverResponseTimeDelays_index = 0;
        this.lastSentMessage = null;
        this.setWebsocketTryingToReconnectStatus=setWebsocketTryingToReconnectStatus ;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 10;
        this.reconnectDelay = 1000; // Initial delay of 1 second
        this.isTabActive=true
        this.connect();
    }

    connect() {
            this.socket = new W3CWebSocket(this.ws_url);
            this.subscribeSocketEvents(this);
            this.socket.onclose = this.onClose.bind(this);
            this.setWebsocketTryingToReconnectStatus(false)
    }

    getReadyState = () => {
        if (this.socket.readyState === 1) {
            return CONNECTION_STATUS.CONNECTED;
        }

        if (this.socket.readyState === 2) {
            return CONNECTION_STATUS.CONNECTING;
        }

        if (this.socket.readyState === 3) {
            return CONNECTION_STATUS.DISCONNECTED;
        }
    };

    ping = () => {
        if (this.socket.readyState === 1 && (this.ws_connection_status != CONNECTION_STATUS.DISCONNECTED)) {
            let msgId = uuidv4();
            this.message_sent_id = msgId;
            this.socket.send(
                JSON.stringify({
                    event: "PING",
                    uid: this.conversation_id ? this.conversation_id : null,
                    message_id: msgId,
                    language_code: this.languageCode,
                })
            );
            if(this.isTabActive!=document.hidden){
                this.isTabActive=document.hidden;
                if (document.hidden) {
                    let inactive_tab_msg = {
                        data: "INACTIVE_TAB",
                        event: "INACTIVE_TAB",
                        uid: this.conversation_id,
                    };
                    let inactiveMsgId = uuidv4();
                    this.socket.send(
                        JSON.stringify({
                            event: "INACTIVE_TAB",
                            uid: this.conversation_id ? this.conversation_id : null,
                            message_id: inactiveMsgId,
                            language_code: this.languageCode,
                        })
                    );
                    this.sendLogToBackend(inactive_tab_msg, "CRITICAL", "BOT");
                    const totalSeconds = new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds();
                    this.updateCountDownTime(totalSeconds);
                    console.log("user tab is inactive")
                }else{
                    let active_tab_msg = {
                        data: "ACTIVE_TAB",
                        event: "ACTIVE_TAB",
                        uid: this.conversation_id,
                    };
                    let activeMsgId = uuidv4();
                    this.socket.send(
                        JSON.stringify({
                            event: "ACTIVE_TAB",
                            uid: this.conversation_id ? this.conversation_id : null,
                            message_id: activeMsgId,
                            language_code: this.languageCode,
                        })
                    );
                    console.log("user tab is active")
                    this.sendLogToBackend(active_tab_msg, "CRITICAL", "BOT");
                    const totalSeconds = new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds();
                    this.updateCountDownTime(totalSeconds);
                }
            }
        
        }
    };

    captureLastResponseFromAPI = (response) => {
        this.lastResponseFromAPI = response;
    };

    getReferralCode = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('referral_code');
        if (referralCode) {
            return referralCode;
        } else {
            return null;
        }
    };

    onClose=(event)=> {
        console.log(`${this.conversation_id}`, "Socket connection is now CLOSED!");
        console.error(`WebSocket closed with code ${event.code} and reason: ${event.reason}`);
        let dataToSend = {
            event: "WEBSOCKET_ONCLOSE",
            data: event.code,
            uid: this.conversation_id,
        };
        this.setWebsocketTryingToReconnectStatus(true)
        this.sendLogToBackend(dataToSend, "CRITICAL", "BOT");
        this.connection_status=this.socket.readyState
        this.webSocketConnectionStateCallBack(this, this.socket.readyState);
        this.resetResponseDelayErrorVariables();

        // Attempt to reconnect
        if (this.socket.readyState === 1) {
            this.reconnect();
        }
    }

    subscribeSocketEvents = (websocket_connection_obj) => {
        this.socket.onopen = () => {
            this.reconnectAttempts = 0
            console.log("Socket connection is now OPEN!");
            this.connection_status=this.socket.readyState
            this.webSocketConnectionStateCallBack(
                websocket_connection_obj,
                this.socket.readyState
            );
            this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
        };

        this.socket.onerror = (event) => {
            console.log("Socket connection is now ERRORD!");
            let dataToSend = {
                event: "WEBSOCKET_ONERROR",
                data: event,
                uid: this.conversation_id,
            };
            this.sendLogToBackend(dataToSend, "CRITICAL", "BOT");
            this.connection_status=this.socket.readyState
            this.webSocketConnectionStateCallBack(
                websocket_connection_obj,
                this.socket.readyState
            );
            this.resetResponseDelayErrorVariables();
        };
    };

    reconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            setTimeout(() => {
                this.reconnectAttempts++;
                console.log(`Reconnecting... attempt ${this.reconnectAttempts}`);
                this.connect();
            }, this.reconnectDelay);
            // Exponential backoff for reconnect attempts
            this.reconnectDelay *= 2;
        } else {
            console.log("Max reconnect attempts reached. Could not reconnect.");
        }
    }

    resetResponseDelayErrorVariables = () => {
        this.showDelayresponseError = false;
        this.setCountDownMessage(null);
        this.setShowCountDown(false);
        clearInterval(this.timer);
        this.timer = null;
    };

    tryingToResendMsgAfterReconnect = (msg) => {
        this.resetResponseDelayErrorVariables();
        let request = JSON.stringify(msg);
        this.socket?.send(request);
        this.sendLogToBackend(msg, "INFO", "USER-RECONNECT");
    };

    handleStopInterval=()=>{
        if (this.timer != null) {
            this.setCountDownMessage(null);
            this.setShowCountDown(false);
            clearInterval(this.timer);
            this.timer = null;
        }

    }

    handleUserResponse = (message, aiResponseCallBackHandler) => {
        if (aiResponseCallBackHandler!==null) {
            this.onMessageCallback=aiResponseCallBackHandler
        }
        
        if (message.event === "BOOK_APPOINTMENT") {
            message.referral_code = this.getReferralCode();
            this.pingTimeInterval = setInterval(() => {
                this.ping();
            }, this.pingTimeout);
        }

        if (message.event === "CLOSE_CONVERSATION") {
            this.setCountDownMessage(null);
            this.setShowCountDown(false);
            clearInterval(this.pingTimeInterval);
            this.pingTimeInterval = null;
            this.captureTheSentMsg(null);
        }

        this.resetResponseDelayErrorVariables();

        if (message.event === "CONNECTED") {
            message.referral_code = this.getReferralCode();
            this.languageCode = message.language_code;
        }

        this.conversation_id = message?.uid;
        let msgId = uuidv4();
        message["message_id"] = msgId;

        let request = JSON.stringify(message);

        if (
            message.event !== "CONNECTED" &&
            message.event !== "CLOSE_CONVERSATION"
        ) {
            this.captureTheSentMsg(message);
            this.message_sent_id = msgId;
            this.setSentMsgId(msgId);
        }

        this.socket.onmessage = (Ai_response) => {
            this.serverResponseTimeDelays_index = 0;
            this.setCountDownMessage(null);
            this.setShowCountDown(false);
            let Message = JSON.parse(Ai_response.data);

            if (Message.type === "ACK") {
                return;
            }

            if (Message.type !== "ACK") {
                this.setLastResponseFromBackend(Message);
            }

            if (Message.type !== "PING" && Message.type !== "ACK") {
                this.captureTheSentMsg(null);
                this.resetResponseDelayErrorVariables();
                this.isMsgCameFromBackend = true;
                let msg = {
                    data: Message.type,
                    event: "RESPONSE_TIMEOUT_TIMER_CLEARED",
                    uid: this.conversation_id,
                };
                this.sendLogToBackend(msg, "INFO", "USER");
            }

            if (Message.type === "UPLOAD") {
                clearInterval(this.pingTimeInterval);
                this.pingTimeInterval = null;
            }

            if (SERVER_GENERATED_RESPONSE_CODES.includes(Message.type)) {
                clearInterval(this.pingTimeInterval);
                this.pingTimeInterval = null;
            }

            if (SERVER_GENERATED_RESPONSE_CODES.includes(Message.type) === false) {
                if (this.lastResponseFromAPI?.message_id === Message.message_id) {
                    return;
                }
            }

            this.onMessageCallback(Ai_response, this.lastResponseFromAPI);
        };

        if (this.socket.readyState === 1) {
            console.log("~~ Sending message:", message);
            this.lastSentMessage = message;
            this.socket.send(request);
            this.sendLogToBackend(message, "INFO", "USER");

            if (message.event !== "CLOSE_CONVERSATION") {
                if (this.timer != null) {
                    this.setCountDownMessage(null);
                    this.setShowCountDown(false);
                    clearInterval(this.timer);
                    this.timer = null;
                }

                let msg = {
                    data: message.event,
                    event: "RESPONSE_TIMEOUT_TIMER_STARTED",
                    uid: this.conversation_id,
                };

                this.sendLogToBackend(msg, "INFO", "USER");

                if (this.timer === null) {
                    this.timer = setInterval(() => {
                        if (this.serverResponseTimeDelays_index === 0) {
                            this.setCountDownMessage("D001");
                            this.setShowCountDown(true);
                        }
                        if (this.serverResponseTimeDelays_index === 1) {
                            this.setCountDownMessage("D002");
                            this.setShowCountDown(true); 
                        }
                        if (this.serverResponseTimeDelays_index === 6) {
                            this.setCountDownMessage("D003");
                            this.setShowCountDown(true);
                        }
                        if (this.serverResponseTimeDelays_index === 12) {
                            this.setCountDownMessage("D004");
                            this.setShowCountDown(true);
                            let msgId = uuidv4();
                            this.lastSentMessage["message_id"] = msgId;

                            if (message.event !== "CONNECTED" && message.event !== "CLOSE_CONVERSATION") {
                                this.captureTheSentMsg(message);
                                this.message_sent_id = msgId;
                                this.setSentMsgId(msgId);
                            }

                            let request = JSON.stringify(this.lastSentMessage);
                            this.socket.send(request);
                            console.log("D004","Retrying... Please wait for next 30 seconds.");
                        }
                        if (this.serverResponseTimeDelays_index === 18) {
                            this.setCountDownMessage(null);
                            this.setShowCountDown(false);
                            this.setReportResponseDelay(true);
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                        this.serverResponseTimeDelays_index++;
                    }, 5000);
                }
            }
            if (this.timer === null) this.setShowCountDown(false);
        }
    };
}

export default WebSocketConnection;
