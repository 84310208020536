import React, { Fragment, useEffect, useState } from "react";
import styles from "./CenterSelection.module.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useDispatch } from "react-redux";
import { CenterNameFunc } from "../../../../ReduxStore/action";
const CenterSelection = ({
  centerlist,
  setSelectedCenter,
  selectedCenter,
  handleBtnEvent,
  disableAllButton
}) => {
  const [centerList, setCenterList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (centerlist) {
      setCenterList(() => [...centerlist]);

    }
  }, [centerlist]);

  const centerSelectionHandler = (option) => {
    dispatch(CenterNameFunc(option));
    handleBtnEvent(option);
  };

  const markup =
    centerList &&
    centerList.map((option, index) => {
      return (
        <div
          key={option.center_id}
          className={styles.vengage__option}
          style={{
            marginTop: "0px",
            display: "flex",
            // backgroundColor: option.doctorId === selectedDoctorId && "#119FDA",
            // color: option.doctorId === selectedDoctorId && "white",
          }}
          onClick={disableAllButton?()=>{}:(e) => centerSelectionHandler(option)}
        >
          <span

            className={styles.vengage__index}
            style={{
              background: "#119FDA",
              border: "50%",
              width: "8px",
              height: "14px",
              fontSize: "12px",
              color: "white",
              // marginTop:"-43px"
              // position: "absolute",
              // top: "12px"

              // borderRadius: "50%",
              // color: "white",
            }}
          >
            {index + 1}
          </span>

          <img
            alt=""
            className={styles.vengage__buttonImage}
            src={`data:image/jpeg;base64,${option.image}`}
          />
          <div className={styles.vengage__centerDetails}>
            <div className={styles.vengage__txt}>{option.text}</div>
            <div
              className={styles.vengage__subtxt}
              style={{ paddingBottom: "5px" }}
            >
              {/* renderListItems(option.list) */} {option.sub_text}
            </div>
            <div
              className={styles.vengage__subtxt}
              style={{ paddingBottom: "1px" }}
            >
              {option.center_address}
            </div>
          </div>
        </div>
      );
    });

  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "300px",
          overflowY: "auto",
          marginTop: "10px",
          scrollbarWidth: "10px !important",
        }}
        id="container"
      >
        {markup}
      </div>
    </Fragment>
  );
};

export default CenterSelection;
