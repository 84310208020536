import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setConversationid } from '../../../ReduxStore/action';
import styles from "./Options.module.css";

// import AppointmentIcon from '../../../assets/icons/AppointmentIcon.png'
// import Bloodtest from '../../../assets/icons/BloodtestIcon.png'
// import CancelAppointment from '../../../assets/icons/CancelAppointmentIcon.png'
// import Query from '../../../assets/icons/QueryIcon.png'


const Options = (props) => {
  const dispatch = useDispatch()
  const [disableButton, setDisableButton] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  // const icons =[AppointmentIcon,Bloodtest,CancelAppointment,Query]

  const { connectionStatus} = useSelector((state) => state.Reducer);
  function handleAIResponse(webSocketMessage) {
    //NOTE:- Making Json into Object
    let ai_response = JSON.parse(webSocketMessage.data);
    props.actionProvider.showResponseDialog(ai_response);
  }

  const handleUserAction = (data, index) => {

    // NOTE:-This showResponseDialog method shows the user response when he click on the option
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: data.text,
    });

    let message = {
      event: data.event,
      uid: props.conversationId,
      data: data.text,
    };

    
    props.wsClient.handleUserResponse(message, handleAIResponse);
  };

  const clickHandler = (option, index) => {
    if (disableButton) {
      return;
    }
    setDisableButton(true);
    setSelectedOption(index);

    dispatch(setConversationid(props.conversationId))

    if(props.captureEventType==="PRESET_CARDIAC_OPTIONS"){
      handleUserAction(option)
    }else{

      props.handleUserAction(option, index);
    }




  };

  const markup = props.defaultOptions&& props.defaultOptions.map((option, index) => (
    <div
      key={index}
      className={styles.vengage__option}
      onClick={connectionStatus==""?() => clickHandler(option, index):()=>{}}
      style={{
        background: selectedOption === index && "#119FDA",
        color: selectedOption === index && "white",
      }}
    >
      <img
        alt=""
        className={styles.vengage__buttonImage}
        src={`data:image/jpeg;base64,${option.image}`}
        // src={icons[index]}
      />
      <div>
        <div className={styles.vengage__txt}>{option.text}</div>
        <div className={styles.vengage__subtxt}>{option.sub_text}</div>
      </div>
    </div>
  ));

  return <div className={styles.vengage__options} style={{display: disableButton && "none"}}>{markup}</div>;
};

export default Options;
