import React from "react";
import chatBotIcon from "./chatboticon.png";
import { useSelector } from "react-redux";

const ChatBotAvatar = (prop) => {
  // console.log("hhh", prop);
  const { botUtteranceIcon} = useSelector((state) => state.Theme);
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <img
          className="react-chatbot-kit-chat-bot-avatar-icon"
        src={botUtteranceIcon!=null?`data:image/jpeg;base64,${botUtteranceIcon}`:null}
        />
      </div>
    </div>
  );
};

export default ChatBotAvatar;
