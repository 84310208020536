export const checkWebsocketConnectionFunc = (connect) => ({
    type: "WEB_SOCKET_CONNECT",
    payload: connect,
});

export const ErrorSetfunc = (Error) => ({
    type: "ERROR",
    payload: Error,
});

export const netWorkconnectionfunc = (netConnection) => ({
    type: "NET_CONNECTION",
    payload: netConnection,
});

export const checkLoadingFunc = (loading) => ({
    type: "LOADING",
    payload: loading,
});

export const centerListFunc = (list) => ({
    type: "CENTER_LIST",
    payload: list,
});

export const showTypebarFunc = (show) => ({
    type: "TYPE_BAR",
    payload: show,
});

export const refreshHandlerFunc = (show) => ({
    type: "REFRESH",
    payload: show,
});

export const clientIdHandlerFunc = (Id) => ({
    type: "CLIENT_ID",
    payload: Id,
});

export const readyStateHandlerFunc = (type, readyState) => ({
    type: type,
    payload: readyState,
});

export const readyStateResetFunc = () => ({
    type: "RESET_READY_STATE",
});

export const webBotTimeOutFunc = (timeOut) => ({
    type: "BOT_TIME_OUT",
    payload: timeOut,
});
export const isIdleFunc = (isIdle) => ({
    type: "IS_IDLE",
    payload: isIdle,
});

export const CenterNameFunc = (data) => {
    const { center_id, center_name } = data
    return {
        type: "CENTER_NAME",
        payload: {
            center_id: center_id,
            center_name: center_name
        },
    }
};

export const countDownFunc = (sec) => ({
    type: "COUNT_DOWN",
    payload: sec,
});

export const startTimerfunc = (timerState) => ({
    type: "START_TIMER",
    payload: timerState,
});

export const doctorIdFunc = (doctor_id) => ({
    type: "DOCTOR_ID",
    payload: doctor_id,
});


export const setwebSocket = (socket) => ({
    type: "SET_WEBSOCKET",
    payload: socket,
})

export const socketReadyStateFunc = (data) => ({
    type: "READY_STATE",
    payload: data
});


export const getErrorCodeFunc = (data) => ({
    type: "GET_ERROR_CODE",
    payload: data
})

export const setIsCloseIconClickedInMobile = (data) => ({
    type: "CLOSE_ICON_CLICKED",
    payload: data
})



export const setChatBot = (data) => ({
    type: "SHOW_BOT_CONVERSATION",
    payload: data
})


export const setShowBotIcon = (data) => ({
    type: "SHOW_BOT_ICON",
    payload: data
})


export const setShowCountDown = (data) => ({
    type: "SHOW_COUNT_DOWN",
    payload: data
})

export const setShowPleaseWait = (data) => ({
    type: "SHOW_PLEASE_WAIT",
    payload: data
})


export const setCountDownTime = (data) => ({
    type: "COUNT_DOWN_TIME",
    payload: data
})

export const updateCountDownTime = (data) => ({
    type: "UPDATE_COUNT_DOWN_TIME",
    payload: data
})

export const setMakeWebSocketConnection = (data) => ({
    type: "REQUEST_FOR_CONNECTION",
    payload: data
})


export const setSelectedLanguageFunc = (data) => ({
    type: "SELECTED_LANGUAGE",
    payload: data
})


export const setLanguageSelectionText = (data) => ({
    type: "SELECT_LANGUAGE_TEXT",
    payload: data
})

export const setOliviaAndRestartText = (data) => ({
    type: "MEET_OVILIVA_RESTART_TEXT",
    payload: data
})

export const setStaticData = (data) => ({
    type: "STATIC_DATA",
    payload: data

})

export const setConversationid = (data) => ({

    type: "CONVERSATION_ID",
    payload: data

})


export const setRebookAppointment = (data) => ({
    type: "REBOOK_APPOINTMENT",
    payload: data
})


export const setReportResponseDelay = (data) => ({
    type: "REPORT_ERROR",
    payload: data
})


export const setIsBookNowClicked = (data) => ({
    type: "BOOK_NOW_CLICKED",
    payload: data
})
export const setIsLanguagePageRendered = (data) => ({
    type: "CHECK_LANGUAGE_PAGE_RENDERED",
    payload: data
})

export const setTriedToSendMsg = (data) => {
    return {
        type: "TRY_TO_SEND_MSG",
        payload: data
    }
}

export const setBookNowButtonClicked = (data) => ({
    type: "BOOK_NOW_BUTTON_CLICKED",
    payload: data
})

export const setSentMsgId = (data) => ({
    type: "SENT_MSG_ID",
    payload: data
})



export const setLastResponseFromBackend = (data) => ({
    type: "LAST_RESPONSE",
    payload: data
})

export const setConnectionStatus = (data) => ({
    type: "CONNECTION_STATUS",
    payload: data
})

export const setTabInfoForMultiCenter = (data) => ({
    type: "TAB_INFO_FOR_MULTI_CENTER",
    payload: data
})


export const setMultiCenterTabCenterId = (data) => {
    console.log("Action setMultiCenterTabCenterId ", data)
    return {
        type: "CENTER_TAB_SELECTION_FOR_MULTI_CENTER",
        payload: data
    }
}

export const setCountDownMessage = (data)=>{
    console.log("payload:",data)
    return {
        type:"SET_COUNT_DOWN_MESSAGE",
        payload:data
    }
}

export const setWebsocketConnectionStatus = (data) => ({
    type: "UPDATE_WEBSOCKET_CONNECTED",
    payload: data
})


export const setWebsocketTryingToReconnectStatus = (data) => {
    return{
    type: "UPDATE_WEBSOCKET_TRYING_TO_RECONNECT_STATUS",
    payload: data
}}