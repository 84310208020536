import React from "react";
import styles from "./UpdateButtion.module.css";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import { useSelector} from "react-redux";

const UpdateButtion = (props) => {
  const {iswebSocketConnected,iswebSocketTryingToReConnect} = useSelector(state=>state.Reducer)

  const handleAIResponse = (webSocketMessage) => {
    // console.log("Booking Update Ai Response ", webSocketMessage);
    let ai_response = JSON.parse(webSocketMessage.data);

    props.actionProvider.showResponseDialog(ai_response);
  };

  const handleBtnEvent = (option) => {
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: option.text,
    });
      let message = {
        event: option.event,
        uid: props.conversationId,
        data: option.text,
      };
      props.wsClient?.handleUserResponse(message, handleAIResponse);
     
  };

  return (
    <center>
      <div
        className={styles.vengage__options__container}
        style={{ width: "80%" }}
      >
        {props.updateButtons != "" &&
          props.updateButtons.map((option, key) => {
            return (
              <CustomButton
                key={key}
                text={option.text}
                disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
                clickHandler={(e) => handleBtnEvent(option)}
              />
            );
          })}
      </div>
    </center>
  );
};

export default UpdateButtion;
