import React, { useState, useEffect } from 'react'
import styles from './CalendarWidget.module.css'
import { useDispatch, useSelector, } from 'react-redux'
import Customdatepicker from '../DatePicker/Customdatepicker'
import { setShowCountDown, setCountDownTime } from '../../../ReduxStore/action'

const CustomTab = ({ data, onClickHandler, selectedTabId }) => {
    const { primaryColor, primaryTextColor } = useSelector((state) => state.Theme);
    return (
        <div
            className={selectedTabId === data?.center_id ? `${styles.vengage__selectedTab} ${styles.vengage__customtab}` : styles.vengage__customtab}
            style={selectedTabId === data?.center_id ? { backgroundColor: primaryColor, color: primaryTextColor } : {}}
            onClick={() => onClickHandler(data)}
        >
            {data?.center_location}
        </div>
    );
};


const ImagingCalendarWidget = (props) => {
    const { staticData } = useSelector((state) => state.Reducer);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isMonthDifferent, setIsMonthDifferent] = useState(false)
    const [centerList, setCenterList] = useState([])
    const [selectedCenterId, setSelectedCenterId] = useState(null);
    const [date, setDate] = useState(null);
    const [confirmationText, setConfirmationText] = useState(null);
    const [workingDays, setWorkingDays] = useState([]);
    const [workflowType, setWorkflowType] = useState("IMAGING_APPT");
    const [calendarMsg, setCalendarMsg] = useState("")
    const [disableProccedButton, setDisableProccedButton] = useState(false)
    const [disableTabData, setDisableData] = useState(false)


    const handleAIResponse = (response) => {
        let ai_response = JSON.parse(response.data)
        console.log("AI Response:", ai_response)
        //    console.log("handleAIResponse Data", ai_response)
        setDate(ai_response.date)
        setConfirmationText(ai_response.confirm_response)

        if (ai_response.working_days && ai_response.working_days.length > 0) {
            let month1 = ai_response.working_days[0].date
            month1 = month1.split('-')
            let month2 = ai_response.working_days[ai_response.working_days.length - 1].date
            month2 = month2.split("-")
            if (Number(month2[1]) != Number(month1[1])) {
                setIsMonthDifferent(true)
            } else {
                setIsMonthDifferent(false)
            }
        }
        setWorkingDays(ai_response.working_days)
        setLoading(false);
        if (ai_response.type === "CONVERSATION_INACTIVE_ALERT") {
            dispatch(setCountDownTime(ai_response.terminate_conversation_countdown_in_seconds))
            dispatch(setShowCountDown(true))

            //    props.actionProvider.showResponseDialog(ai_response);
        }
        if (ai_response.message !== null && (ai_response.type != "HANG_UP" && ai_response.type != "CONVERSATION_TERMINATED_BY_CONV_ENGINE" && ai_response.type !== "CONVERSATION_INACTIVE_ALERT")) {
            console.log("ai_response.message",ai_response.message)
            setCalendarMsg(ai_response.message)
        }

        if (ai_response.message && ai_response.type === "HANG_UP" || ai_response.type === "CONVERSATION_TERMINATED_BY_CONV_ENGINE") {
            props.actionProvider.showResponseDialog(ai_response);
            setDisableProccedButton(true)
        }



    }

    useEffect(() => {
        if (Array.isArray(props.centerList)) {

            setCenterList(props.centerList)
            let selected_center_id = props?.selected_tab_id ? props?.selected_tab_id : props.centerList[0].center_id;
            setSelectedCenterId(selected_center_id)
        }

        setLoading(true)



        return () => {
        }
    }, [])

    // Fetch calendar and populate UI.
    useEffect(() => {
        if (selectedCenterId == null) return;
        let message = {
            event: "FETCH_IMAGING_CALENDAR",
            uid: props.conversationId,
            center_id: selectedCenterId
        };
        props.wsClient.handleUserResponse(message, handleAIResponse);

        return () => {
        }
    }, [selectedCenterId])

    const tabSelectionHandler = (data) => {
        if (loading) return;
        if (data?.center_id === selectedCenterId) return;

        setSelectedCenterId(data?.center_id);
        setLoading(true)
    };
    const getKey = (index) => {
        return index + "_" + Date();
    }
    const delayFetchingCalenderText=staticData?.delay_in_fetching_calendar_data ? staticData?.delay_in_fetching_calendar_data  :"This may take upto 30 seconds" ;
    return (
        <div className={styles.vengage__showDatePickerWithTab} style={{ display: disableTabData && "none" }}>
            <div className={styles.vengage__tabs}>
                {(centerList && centerList.length > 1) && centerList?.map((each, index) => (
                    <CustomTab
                        selectedTabId={selectedCenterId}
                        data={each}
                        // key={getKey(index)}
                        onClickHandler={(data) => tabSelectionHandler(data)}
                    />
                ))}
            </div>
            <div className={!isMonthDifferent ? styles.vengage__datePicker__oneMonth : styles.vengage__datePicker} style={{ borderTopLeftRadius: centerList && centerList.length === 1 && "8px,", height: centerList && centerList.length > 1 && "395px" }}  >
                {loading ? <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center", height: "337px" }}>
                    <p className={styles.vengage__dot_pulse}></p><div style={{ fontStyle:"italic", fontSize:"10px" }}><p dangerouslySetInnerHTML={{__html:delayFetchingCalenderText}}></p> </div></div> : <Customdatepicker Date={date} workingDays={workingDays} setDisableData={setDisableData}{...props} center_id={selectedCenterId} workflowType={workflowType} calendarMsg={calendarMsg} compIdentity="tab_component" disableAllButton={disableProccedButton} confirmatrionText={confirmationText} centerList={centerList} />}
            </div>
        </div>
    )
}

export default ImagingCalendarWidget