import React from "react";
import styles from "./CustomButton.module.css";
import { useSelector } from "react-redux";
const CustomButton = (props) => {
  const { connectionStatus,iswebSocketTryingToReConnect} = useSelector((state) => state.Reducer);
  const {activeButtonColor,activeButtonTextColor,inactiveButtonColor,inactiveButtonTextColor} = useSelector((state) => state.Theme);
  return (
    <button
      className={styles.vengage__btn__primary}
      type="button"
      onClick={props.clickHandler}
      disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect: connectionStatus!=""?true: props.disabled}
      style={{...props.customStyle,
        backgroundColor:iswebSocketTryingToReConnect?inactiveButtonColor:(connectionStatus==""?(!props.disabled ? (activeButtonColor!=null?activeButtonColor:null):inactiveButtonColor):inactiveButtonColor),
        color:iswebSocketTryingToReConnect?inactiveButtonTextColor:(connectionStatus==""?(!props.disabled ? (activeButtonTextColor!=null?activeButtonTextColor:null):inactiveButtonTextColor):inactiveButtonTextColor),
      }}
    >
      {props.text}
    </button>
  );
};

export default CustomButton;
