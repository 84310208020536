import React, { useEffect } from "react";
import { FadeIn } from "react-anim-kit";
import { ConditionallyRender } from "react-util-kit";

import { ReactComponent as ActionProviderOverview } from "../../../assets/img/actionprovider-overview.svg";

import styles from "./index.module.css";
import InformationBox from "../InformationBox/InformationBox";

const Suggestions = (props) => {
  
  const suggestions = [
    { 
      event: "SUGGESTIONLIST",
      text: "Grose Street, Parramatta ",
      image: "1.jpg",
      list: ['Specialist Imaging Centre', 'Early slot: Monday 11:30'],
      handler: props.actionProvider.handleSuggestionSelection,
      id: 1,
    },
    { 
      event: "SUGGESTIONLIST",
      text: "Grose Street, Parramatta",
      image: "1.jpg",
      list: ['3 Centres', 'Early slot: Monday 11:30'],
      handler: props.actionProvider.handleSuggestionSelection,
      id: 1,
    },
    { 
      event: "SUGGESTIONLIST",
      text: "Parramatta",
      image: "1.jpg",
      list: ['3 Centres', 'Early slot: Monday 11:30'],
      handler: props.actionProvider.handleSuggestionSelection,
      id: 1,
    }
  ]
    
  const renderListItems = (items) =>{
    return items.map((item)=>{
      return(
      <div>{item}</div>
      )
    })
  } 

  const markup = suggestions.map((option) => (
    <div key={option.id} className={styles.option} onClick={option.handler}>
      <img alt="" className={styles.buttonImage} src={require('./1.jpg')} />
      <div>
          <div className={styles.txt}>{option.text}</div>
          <div className={styles.subtxt}>{renderListItems(option.list)}</div>
      </div>
    </div>
  ));


  return (
    <div className={styles.options}>
      <div className={styles.header}><b className={styles.headerTxt}>Suggest Nearest to you</b><hr className={styles.hr}/></div>  
      <FadeIn left>
        {markup} 
      </FadeIn>

    </div>
  );
};

export default Suggestions;
