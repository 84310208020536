import React, { useState, useEffect } from "react";
import Calendar from "../CustomCalendar/Calendar";
import styles from "./styles.module.css";
const Datelist = ({
  datelist,
  setSelectedDate,
  selectedDate,
  month,
  year,
  setDateToggle,
  dateToggle,
  list,
  WeekDays,
  setDisableButton,
  MonthList,
  monthArray,
  // setMonthLastDayIndex,
  // monthLastDayIndex,
  // setMonthFirstDayIndex,
  // monthFirstDayIndex,
  setShowSelectedUserDate,
}) => {
  const [monthLastDayIndex,setMonthLastDayIndex ] = useState('')
  const [monthFirstDayIndex, setMonthFirstDayIndex] = useState('')
  // const [selectedDate, setSelectedDate] = useState("");
 


  const dayWithIndex = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [selected, setSelected] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    if (datelist) {
      let start_date = datelist[0].date.split("-");
      let firstDay = new Date(
        `${start_date[1]}/${start_date[2]}/${start_date[0]}`
      ).getDay();


      
      let firstWeekDay = WeekDays[firstDay].shortName;

      if (list == 1 ||list == 2) {
        dayWithIndex.forEach((day, index) => {
          if (firstWeekDay == day) {
            setMonthFirstDayIndex(index);
          }
        });
      }
      let end_date = datelist[datelist.length - 1].date.split("-");

      // Starting date of calendar and end date
      setStartDate(start_date[2]);
      setEndDate(end_date[2]);


       // getting the Day name of the last date of month
      //But new Date('mm/dd/yyyy') format workes all the browser
      let Day = new Date(
        `${end_date[1]}/${end_date[2]}/${end_date[0]}`
      ).getDay();

      //
      let weekDay = WeekDays[Day].shortName;

      if (monthArray && monthArray.length == 2) {
        dayWithIndex.forEach((day, index) => {
          if (weekDay === day) {
            setMonthLastDayIndex(index);
          }
        });
      }
    }
  }, []);

  return (
    <div>
      {month != undefined && (
        <div
          className={styles.vengage__dateList__monthYear}
        >{`${MonthList[month].shortName} , ${year}`}</div>
      )}
      <div style={{ width: "100%`" }}>
        {startDate && endDate && (
          <Calendar
            month={month}
            year={year}
            startDate={startDate}
            endDate={endDate}
            datelist={datelist}
            monthLastDayIndex={monthLastDayIndex}
            monthFirstDayIndex={monthFirstDayIndex}
            list={list}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setShowSelectedUserDate={setShowSelectedUserDate}
          />
        )}
      </div>
    </div>
  );
};

export default Datelist;
