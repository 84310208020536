import React, { Fragment, useEffect, useState } from "react";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import BookingDetails from "./BookingDetails";
import MonthList from "../Methods/MonthList";
import styles from "./index.module.css";
import { useSelector} from "react-redux";


// NOTE:- this component tells about the appointment details
function BookingOverview(props) {

  const [name, setName] = useState("")
  const [Dob, setDob] = useState("")
  const [emailId, setEmailId] = useState("")
  const [Phone, setPhone] = useState("")
  const [scanType, setScanType] = useState("")
  const [center_id, setCenter_id] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [centerPrefix, setCenterPrefix] = useState("")
  const [centerName, setCenterName] = useState("")
  const [centerAddress, setCenterAddress] = useState("")

  const [scheduleDatetime, setScheduleDatetime] = useState("")
  const [doctorName, setDoctorName] = useState("")
  const [insuranceType, setInsuranceType] = useState("")
  const [insuranceNumber, setInsuranceNumber] = useState("")

  const [disable, setDisable] = useState(false);
  let CenterName = props.userDetails.confirm_details.center_name;
  let CustomerName = props.userDetails.confirm_details.customer_name;
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [patientDob, setPatientDob] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  useEffect(() => {
    if (props.userDetails.confirm_details.schedule_datetime) {
      let dateTime =
        props.userDetails.confirm_details.schedule_datetime.split(" ");
      let date = dateTime[0].split("-");
      let monthNDate = new Date(
        `${date[2]}-${date[1]}-${date[0]}`
      ).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      monthNDate = monthNDate.split(" ");
      setAppointmentDate(
        () =>
          `${monthNDate[1]}-${monthNDate[0]}-${date[2]} ${dateTime[1]} ${dateTime[2]}`
      );
    }
    // if (props.userDetails.confirm_details.dob) {
    //   let date = props.userDetails.confirm_details.dob.split("-");
    //   setPatientDob(
    //     () => `${date[0]}-${MonthList[Number(date[1] - 1)]}-${date[2]}`
    //   );
    // }


    props.userDetails && props.userDetails.data_list && props.userDetails.data_list.forEach((item, index)=>{
      if(item.preset_response==='phone'){
        setPhone(item)

      }else if(item.preset_response==='dob'){
        let date = props.userDetails.confirm_details.dob.split("-");
      setPatientDob(
        () => `${date[0]}-${MonthList[Number(date[1] - 1)]}-${date[2]}`
      );
      let data ={...item , value:`${date[0]}-${MonthList[Number(date[1] - 1)]}-${date[2]}`}
        setDob(data)
        
      }else if(item.preset_response==='name'){
        setName(item)
        
      }else if(item.preset_response==='center_id'){
        setCenter_id(item)
      }else if(item.preset_response==='customer_name'){
        setCustomerName(item)
        
      }else if(item.preset_response==='center_prefix'){
        setCenterPrefix(item)
        
      }else if(item.preset_response==='center_name'){
        let center_name = item.value.includes(customerName.value)? item.value:`${customerName.value}-${item.value}`

        let data = {...item, value:center_name}

        // centerName && centerName.includes(customerName)
        //         ? centerName
        //         : `${ customerName } - ${ centerName }`
        setCenterName(item)
        
      }else if(item.preset_response==='center_address'){
        setCenterAddress(item)
      }else if(item.preset_response==='schedule_datetime'){
        let dateTime =
        props.userDetails.confirm_details.schedule_datetime.split(" ");
      let date = dateTime[0].split("-");
      let monthNDate = new Date(
        `${date[2]}-${date[1]}-${date[0]}`
      ).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
      monthNDate = monthNDate.split(" ");
      setAppointmentDate(
        () =>
          `${monthNDate[1]}-${monthNDate[0]}-${date[2]} ${dateTime[1]} ${dateTime[2]}`
      );

      let data ={...item, value: `${monthNDate[1]}-${monthNDate[0]}-${date[2]} ${dateTime[1]} ${dateTime[2]}`}
        setScheduleDatetime(data)
        
      }else if(item.preset_response==='doctor_name'){
        setDoctorName(item)
        
      }else if(item.preset_response==='insurance_type'){
        setInsuranceType(item)
        
      }else if(item.preset_response==='insurance_number'){
        setInsuranceNumber(item)
        
      }else if(item.preset_response==="scan_type"){
        setScanType(item)
      } else if (item.preset_response==='email_id') {
        setEmailId(item)
      }

    })

  }, [props.userDetails]);





  const handleAIResponse = (webSocketMessage) => {
    if (webSocketMessage) {
      // console.log("Booking overview Ai Response ", webSocketMessage);
      let ai_response = JSON.parse(webSocketMessage.data);
      if (
        ai_response.type == "PRESET" &&
        ai_response.preset_responses.length > 0
      ) {
        ai_response.type = "UPDATE_BUTTONS";
        //   ai_response.preset_responses[0]["style"] = {
        //     color: "#000",
        //     border: "1px solid #000",
        //   };
      }
      props.actionProvider.showResponseDialog(ai_response);
    } else {
      props.actionProvider.showResponseDialog({ type: "LOADING_MESSAGE" });
    }
  };

  const handleBtnEvent = (option) => {
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: option.text,
    });

    let message ={
      event: option.event,
      uid: props.conversationId,
      data: option.text,
    };
    // if(props.workflowType==="GP_APPT"){
    //  message.event="GP_DEMOGRAPHICS_CONFIRM"
    // }

    

      props.wsClient?.handleUserResponse(message, handleAIResponse);
      setDisable(true);
  };

  const handleSlotSelection = (slot_time) => {
    setSelectedSlot(slot_time);
  };

  
  return (
    <Fragment>
      <div className={styles.vengage__options}>
        <div className={styles.vengage__overview__block}>
          <BookingDetails
            patientDob={Dob}
            PatientName={name}
            DOB={Dob}
            emailId={emailId}
            Phone={Phone}
            ScanType={scanType}
            DoctorName={doctorName}
            Center={centerName}
            AppointmentDate={scheduleDatetime}
            workflowType={props.workflowType}
            medicareNo={insuranceNumber}
            insuranceType={insuranceType}
            centerAddress={centerAddress}
            data_list={props.userDetails.data_list}
          />

          <center>
            <div
              className={styles.vengage__options__container}
              style={{ width: "90%" }}
            >
              {props.userDetails.preset_responses.map((option, index) => {
                return (
                  <CustomButton
                    key={index}
                    text={option.text}
                    clickHandler={(e) => handleBtnEvent(option)}
                    disabled={disable ||props.disableAllButton}
                  />
                );
              })}
            </div>
          </center>
        </div>
      </div>
    </Fragment>
  );
}

export default BookingOverview;
