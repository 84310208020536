import { act } from "react-dom/test-utils";

const initialState = {
    readyStateOne: false,
    readyStateThree: false,
    webSocketConnected: false,
    Error: null,
    interNetConnection: null,
    loading: false,
    centerList: null,
    showTypebar: false,
    reduxRefreshHandler: false,
    Client_ID: "",
    botTimeOut: false,
    isIdle: null,
    countDown: 15,
    startTimer: false,
    CenterName: "",
    doctorId: "",
    wsClient: null,
    iswebSocketConnected: null,
    getErrorCode: null,
    closeIconclickedOnMobile: false,
    showChatBot: "none",
    showBotIcon: "",
    showCountDown: false,
    showPleaseWait:false,
    countDownTime: null,
    countDownDuration:null,
    isWebSocketConnectionRequested: false,
    selected_language: "",
    language_selection_text: "",
    meet_olivia_text: "",
    restart_chat_text: "",
    staticData: "",
    conversationId: "",
    rebookAppointmentText: "",
    delayResponseReported: false,
    isBookNowButtonClicked: false,
    isLanguageSelectionPageRendered: false,
    triedToSendMsg: null,
    sentMsgId: null,
    lastResponseFromBackend: "",
    connectionStatus: null,
    selectedCenterId: null,
    centerTabInfoForMultiCenter: [],
    selectedCenterIdForMultiCenter: null,
    dummyCenterForMultiCenter: null,
    countDownMessage:null,
    initialWebsocketConnectionEstablished:false,
    convInactiveAlertMsgTime:null,
    iswebSocketTryingToReConnect:true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "WEB_SOCKET_CONNECT":
            return {
                ...state,
                webSocketConnected: action.payload,
            };
        case "ERROR":
            return {
                ...state,
                Error: action.payload,
            };
        case "NET_CONNECTION":
            return {
                ...state,
                interNetConnection: action.payload,
            };
        case "LOADING":
            return {
                ...state,
                loading: action.payload,
            };

        case "CENTER_LIST":
            return {
                ...state,
                centerList: action.payload,
            };
        case "TYPE_BAR":
            return {
                ...state,
                showTypebar: action.payload,
            };
        case "REFRESH":
            return {
                ...state,
                reduxRefreshHandler: action.payload,
            };
        case "CLIENT_ID":
            return {
                ...state,
                Client_ID: action.payload,
            };
        case "READY_STATE_ONE":
            return {
                ...state,
                readyStateOne: true,
                webSocketConnected: action.payload,
            };
        case "READY_STATE_THREE":
            return {
                ...state,
                readyStateThree: true,
                webSocketConnected: action.payload,
            };

        case "RESET_READY_STATE":
            return {
                ...state,
                readyStateThree: false,
                readyStateOne: false,
            };
        case "BOT_TIME_OUT":
            return {
                ...state,
                botTimeOut: action.payload,
            };
        case "CENTER_NAME":
            const { center_name, center_id } = action.payload
            return {
                ...state,
                CenterName: center_name,
                selectedCenterId: center_id

            };
        case "IS_IDLE":
            return {
                ...state,
                isIdle: action.payload,
            };

        case "COUNT_DOWN":
            return {
                ...state,
                countDown: action.payload,
            };
        case "START_TIMER":
            return {
                ...state,
                startTimer: action.payload,
            };
        case "DOCTOR_ID":
            return {
                ...state,
                doctorId: action.payload,
            };

        case "SET_WEBSOCKET":
            return {
                ...state,
                wsClient: action.payload,
            };

        case "READY_STATE":
            return {
                ...state,
                iswebSocketConnected: action.payload,
            };
        case "GET_ERROR_CODE":
            return {
                ...state,
                getErrorCode: action.payload,
            };

        case "CLOSE_ICON_CLICKED":
            return {
                ...state,
                closeIconclickedOnMobile: action.payload,
            }

        case "SHOW_BOT_ICON":
            return {
                ...state,
                showBotIcon: action.payload,
            }
        case "SHOW_BOT_CONVERSATION":
            return {
                ...state,
                showChatBot: action.payload,
            }

        case "SHOW_COUNT_DOWN":
            return {
                ...state,
                showCountDown: action.payload
            }

        case "SHOW_PLEASE_WAIT":
            return {
                ...state,
                showPleaseWait: action.payload
            }

        case "COUNT_DOWN_TIME":
            return {
                ...state,
                countDownDuration:action.payload,
                countDownTime: action.payload
            }
        case "UPDATE_COUNT_DOWN_TIME" :
            // If the user switches tabs or becomes active/inactive,then countDownTime is not working correctly,so reset the "countDownTime":
           // Example: Event received (state.convInactiveAlertMsgTime) in seconds format at 1260 sec.
          //  User became active at 1270 (taken from system/action.payload) in seconds format at 1260 sec.
          // Subtract 10 seconds from the countDownTime.
            if(state.convInactiveAlertMsgTime && state.showCountDown){
                //find diff ( user_active_time - event_recived_time ) 1270-1260 =10 sec
                let final_updated_time=action.payload-state.convInactiveAlertMsgTime;
                if(final_updated_time>0 && state.countDownTime>0 && state.countDownDuration-final_updated_time>0){
                    return {
                        ...state,
                        //substract diff time
                        countDownTime:state.countDownDuration-final_updated_time
                     }
                }else{
                    return {
                        ...state
                     }
                }
            }
        case "REQUEST_FOR_CONNECTION":
            return {
                ...state,
                isWebSocketConnectionRequested: action.payload
            }
        case "SELECTED_LANGUAGE":
            return {
                ...state,
                selected_language: action.payload
            }
        case "SELECT_LANGUAGE_TEXT":
            return {
                ...state,
                language_selection_text: action.payload
            }
        case "MEET_OVILIVA_RESTART_TEXT":
            //  console.log("MEET_OVILIVA_RESTART_TEXT", action.payload)
            const { meet_olivia_text, restart_chat_text } = action.payload
            return {
                ...state,
                meet_olivia_text: meet_olivia_text,
                restart_chat_text: restart_chat_text
            }
        case "STATIC_DATA":
            return {
                ...state,
                staticData: action.payload
            }
        case "CONVERSATION_ID":
            return {
                ...state,
                conversationId: action.payload
            }
        case "REBOOK_APPOINTMENT":
            return {
                ...state,
                rebookAppointmentText: action.payload
            }
        case "REPORT_ERROR":
            return {
                ...state,
                delayResponseReported: action.payload
            }
        case "CHECK_LANGUAGE_PAGE_RENDERED":
            return {
                ...state,
                isLanguageSelectionPageRendered: action.payload
            }
        case "TRY_TO_SEND_MSG":
            return {
                ...state,
                triedToSendMsg: action.payload
            }

        case "BOOK_NOW_CLICKED":
            return {
                ...state,
                isBookNowButtonClicked: action.payload
            }
        case "SENT_MSG_ID":
            return {
                ...state,
                sentMsgId: action.payload
            }
        case "LAST_RESPONSE":
            if(action.payload?.type=="CONVERSATION_INACTIVE_ALERT"){
                const totalSeconds = new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds();
                return {
                    ...state,
                    lastResponseFromBackend: action.payload,
                    convInactiveAlertMsgTime:totalSeconds
                }
            }else{
                return {
                    ...state,
                    lastResponseFromBackend: action.payload,
                    convInactiveAlertMsgTime:null
                }
            }
        case "CONNECTION_STATUS":
            return {
                ...state,
                connectionStatus: action.payload
            }
        case "TAB_INFO_FOR_MULTI_CENTER":
            return {
                ...state,
                centerTabInfoForMultiCenter: action.payload
            }
        case "SET_COUNT_DOWN_MESSAGE":
            if(state?.staticData?.response_delay){
                let updatedCountDownMessage=action.payload ;
                const {first_message,second_message,third_message,retry_message}=state?.staticData?.response_delay ;
                
                //Based on the updated error code, modify the delay message.
    
                if(action.payload=="D001"){
                    updatedCountDownMessage=first_message?first_message:"Please wait. I am processing your request." ;
                }else if(action.payload=="D002"){
                    updatedCountDownMessage=second_message?second_message:"It is taking a bit longer. Please wait."
                }else if(action.payload=="D003"){
                    updatedCountDownMessage=third_message?third_message:"Sorry, it is longer than expected. Please do not disconnect."
                }else if(action.payload=="D004"){
                    updatedCountDownMessage=retry_message?retry_message:"Retrying. Please wait for next 30 seconds."
                }else{
                    updatedCountDownMessage=null
                }
                
                return {
                    ...state,
                    countDownMessage: updatedCountDownMessage
                }
            }else{
                let updatedCountDownMessage=action.payload ;
                if(action.payload=="D001"){
                    updatedCountDownMessage="Please wait. I am processing your request." ;
                }else if(action.payload=="D002"){
                    updatedCountDownMessage="It is taking a bit longer. Please wait."
                }else if(action.payload=="D003"){
                    updatedCountDownMessage="Sorry, it is longer than expected. Please do not disconnect."
                }else if(action.payload=="D004"){
                    updatedCountDownMessage="Retrying. Please wait for next 30 seconds."
                }else{
                    updatedCountDownMessage=null
                }
                return {
                    ...state,
                    countDownMessage: updatedCountDownMessage
                }
            }
        case "CENTER_TAB_SELECTION_FOR_MULTI_CENTER":
            return {
                ...state,
                selectedCenterIdForMultiCenter: action.payload.center_id,
                dummyCenterForMultiCenter: action.payload.dummy_center_id
            }
            case "UPDATE_WEBSOCKET_CONNECTED":
                return {
                    ...state,
                    initialWebsocketConnectionEstablished: action.payload
                }
        case "UPDATE_WEBSOCKET_TRYING_TO_RECONNECT_STATUS" :
            return {
                ...state,
                iswebSocketTryingToReConnect:action.payload
            }
        default:
            return state;
    }
}

