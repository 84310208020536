import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LanguageStore from "../../../data/LanguageStore";
import fetchStaticData from "../../../data/StaticData";
import Logger from "../../../Methods/Logger";
import { 
    setMakeWebSocketConnection, 
    setSelectedLanguageFunc, 
    setStaticData , 
    setIsLanguagePageRendered, 
    setWebsocketConnectionStatus
    } from '../../../ReduxStore/action';
import styles from './LanguageSelection.module.css';



const LanguageSelection = (props) => {
    const dispatch = useDispatch()
    let botInput1 = document.getElementsByClassName(
        "react-chatbot-kit-chat-input"
    );
    let sendButton = document.getElementsByClassName(
        "react-chatbot-kit-chat-btn-send"
    );
    
    const [selectedLanguage, setSelectedLanguage] = useState("")

    const { 
        isWebSocketConnectionRequested, 
        wsClient, 
        iswebSocketConnected, 
        Client_ID, 
        reduxRefreshHandler, 
        selected_language, 
        singleLanguageSelection, 
        webSocketresponse, 
     } = useSelector(state => state.Reducer)

    let botInput = document.querySelector("#react_chatbot_kit_input");

    function handleAIResponse(webSocketMessage) {

        let ai_response = JSON.parse(webSocketMessage.data);
        if (ai_response.type === "PRESET") {
            ai_response.type = "PRESET_DEFAULT";
        }

        // NOTE: - This showResponseDialog method is written into actionProvider component.it stores the airesponse.preset_responses data
        // into defaultOptions state which is written into cofig component
        props.actionProvider.showResponseDialog(ai_response);
    }




    useEffect(() => {   // Disabling the input box

        if (botInput && botInput1 && botInput1.length != 0) {
            botInput1[0].disabled = true;
            botInput.setAttribute('placeholder', "")
        }

        if (sendButton.length != 0 && sendButton[0].disabled != undefined) {
            sendButton[0].disabled = true;
        }

    }, [botInput])

    //Added a id to the input tag
    useEffect(() => {
        var chatBotInput = document.getElementsByClassName('react-chatbot-kit-chat-input');
        if (chatBotInput && chatBotInput.length != 0) {

            chatBotInput[0].id = "react_chatbot_kit_input"
        }

        dispatch(setIsLanguagePageRendered(true))

    }, [])


    const [disableButton, setDisableButton] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        var initialMessage = {
            event: "CONNECTED",
            uid: props.conversationId,
            ClientId: Client_ID,
            // language_code: languageSelectionData.length===1 ?  selected_language:selectedLanguage
        };
        dispatch(setWebsocketConnectionStatus(true));

        // // When language selection is more than one 
        if (iswebSocketConnected == "CONNECTED" && wsClient && isWebSocketConnectionRequested && LanguageStore.data.length > 1 && selectedLanguage) {

            let message = {
                ...initialMessage,
                language_code: selectedLanguage

            }
            wsClient.handleUserResponse(message, handleAIResponse);
            props.actionProvider.showResponseDialog({
                type: "SET_WEBSOCKET_TO_STATE",
                data: wsClient,
            });
        }

    }, [isWebSocketConnectionRequested, reduxRefreshHandler, iswebSocketConnected, wsClient, selectedLanguage])




    



    //   const Options=[{sub_text: "",text: "Select a English1"},{sub_text: "",text: "Select a English2"},{sub_text: "",text: "Select a English3"} ]

    const clickHandler = (option, index) => {


        dispatch(setMakeWebSocketConnection(true)) // Requesting to make a websocket connection on the language selection
        fetchStaticData(dispatch, Client_ID, option.language_code);
        setSelectedLanguage(option.language_code)
        dispatch(setSelectedLanguageFunc(option.language_code))


        props.actionProvider.showResponseDialog({
            type: "LANGUAGE_SELECTION",
            message: option.language,
        });


        setDisableButton(true);




    };

    return <div className={styles.vengage__options} style={{ display: disableButton && "none" }}>
        { LanguageStore.data &&  LanguageStore.data.map((option, index) => (
            <div
                key={index}
                className={styles.vengage__option}
                onClick={() => clickHandler(option, index)}
                style={{
                    background: selectedOption === index && "#119FDA",
                    color: selectedOption === index && "white",
                }}
            >
                <div className={styles.vengage__flag}>
                    <img
                        alt=""
                        className={styles.vengage__buttonImage}
                        src={`data:image/jpeg;base64,${option.image}`}
                    // src={icons[index]}
                    />
                </div>
                <div>
                    <div className={styles.vengage__txt}>{option.language}</div>
                </div>
            </div>
        ))}
    </div>;
};

export default LanguageSelection;
