import React, { useState, useEffect, Fragment } from "react";
import styles from "./DoctorSelection.module.css";
import SearchBar from "material-ui-search-bar";
import { CenterNameFunc, doctorIdFunc } from "../../../../ReduxStore/action";
import NoDataFound from "../../../../assets/img/NoDataFound.png";

import { useDispatch } from "react-redux";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CustomButton from "../../../../UI/CustomButton/CustomButton";
const DoctorSelection = ({ handleBtnEvent, centerlist ,disableAllButton, confirm_selection, placeholder_text, scrollIntoView}) => {
  const dispatch = useDispatch();
  const [centerList, setCenterList] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [selectedDoctorId, setSelectedDoctorId] = useState("");
  const [storedCenterList, setStoredCenterList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showDownArrow, setDownArrow] = useState(true);
  const [showUpArrow, setUpArrow] = useState(false);

  let searchBarField = document.getElementsByClassName("MuiInputBase-input");
  useEffect(() => {
    if( scrollIntoView){

      scrollIntoView();
    }
  });
  useEffect(() => {
    if (centerlist && centerlist.length!=0) {
      // console.log("centerList",centerlist)
      setCenterList(() => [...centerlist]);
      setStoredCenterList(() => [...centerlist]);
      // setCenterList(() => [...centerlist.slice(0,4)]);
      // setStoredCenterList(() => [...centerlist.slice(0,4)]);
    }

    // searchBarField[0].attributes.placeholder.nodeValue = "Search a doctor...";
  }, [centerlist]);

  const selectDoctorHandler = (option) => {
    if(disableAllButton){
      return true
    }
    setSelectedData(option);
    setSelectedDoctorId(option.doctorId);
  };
  //search bar functionality

  const searchbarHandler = (searchedData) => {
    setSearchValue(searchedData);
    let filtereddata = storedCenterList.filter((doctor) =>
      doctor.text.toLowerCase().includes(searchedData.toLowerCase())
    );

    setCenterList(() => [...filtereddata]);
  };
  //NOTE:- Scrolling the list step by step
  const scrollToBottom = () => {
    setUpArrow(true);
    setDownArrow(false);
    const scrollContainer = document.getElementById("container");
    // console.log("scrollContainer", scrollContainer.scrollHeight);

    scrollContainer.scrollBy({
      top: 150,
      left: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    setUpArrow(false);
    setDownArrow(true);
    const scrollContainer = document.getElementById("container");

    scrollContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const clearSearchField = () => {
    setCenterList(() => [...storedCenterList]);
  };
  const confirmationHandler = (option) => {
    dispatch(doctorIdFunc(option.doctorId));
    handleBtnEvent(option);
  };

 
  const doctrosList =
    centerList && centerList.length>0 &&
    centerList.map((option, index) => {
      return (
        <Fragment>
        {/*<p style={{height:"2px",margin:"0px"}}></p>*/}
        <div
          key={option.center_id}
          className={styles.option}
          style={{
            marginTop: "0px",
            display: "flex",
            backgroundColor:option.doctorId &&  option.doctorId === selectedDoctorId && "#119FDA",
            color:option.doctorId && option.doctorId === selectedDoctorId && "white",
          }}
          onClick={(e) => selectDoctorHandler(option)}
        >
          <img
            alt=""
            className={styles.vengage__docImage}
            src={`data:image/jpeg;base64,${option.image}`}
          />
          <div>
            <div className={styles.vengage__txt}>{option.text}</div>
            <div
              className={styles.vengage__subtxt}
              style={{ paddingBottom: "5px" }}
            >
              {/* renderListItems(option.list) */} {option.sub_text}
            </div>
            <div
              className={styles.vengage__subtxt}
              style={{ paddingBottom: "1px" }}
            >
              {option.center_address}
            </div>
          </div>
        </div>
        </Fragment>

      );
    });

  return (
    <Fragment>
    <div className={styles.doctor_list_N_searchbar}  >
      {/*<SearchBar
      value={searchValue}
      onChange={(newValue) => searchbarHandler(newValue)}
      // onRequestSearch={() => filterDoctor(searchValue)}
      onCancelSearch={clearSearchField}
      />*/}
      <div className={styles.search_inputbar}>
        <input type="text" onChange={(e)=>searchbarHandler(e.target.value)} className={styles.inputbar} placeholder={placeholder_text} />
      </div>

        <div
        // className={styles.vengage_doctor_list}
          style={{
            maxHeight: "310px",
           
          }}
          
        >
        <div className={styles.vengage_doctor_list}  
        style={{
          width: "100%",
          maxHeight: "310px",
          overflowY: "scroll",
          marginTop: "2px",
          scrollbarWidth: "10px !important",
          // display:"flex",
          // flexDirection:"column"
          // border:"1px solid lightgray"
        }}
        id="container"
        
        >
          {centerList && centerList.length === 0 ? (
            <center>
              <img src={NoDataFound} style={{ height: "100px" }} />
              <p style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
                Please try other doctor.
              </p>
            </center>
          ) : (
            doctrosList
          )}
        </div>
         <div style={{height:"40px"}}>
          {centerList && centerList.length !== 0  &&  selectedDoctorId.length!=0 && (
            <center style={{marginTop:"5px"}}>
              <CustomButton
                clickHandler={(e) => confirmationHandler(selectedData)}
                text={`${confirm_selection}`}
                disabled={!selectedDoctorId || disableAllButton}
                customStyle={{display:disableAllButton&& "none"}}
              //   customStyle={{paddingLeft:"20px !important",
              //   paddingRight:"20px !important"
              
              // }}
              ></CustomButton>
            </center>
          )}
         </div>

        </div>
    </div>


      {centerList.length > 8 && (
        <div
          className={styles.scrollTobottom}
          style={{
            position: "absolute",
            right: -30,
            bottom: -30,
            padding: "5px",
            // background: "lightgrey",
            borderRadius: "5px",
          }}
        >
          <KeyboardDoubleArrowDownIcon onClick={() => scrollToBottom()} />
        </div>
        )}

     
    </Fragment>
  );
};

export default DoctorSelection;
