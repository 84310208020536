import { Fragment, useEffect, useState } from "react";
import Chatbot from "react-chatbot-kit";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { ReactComponent as CloseButtonIcon } from "./assets/icons/cancel.svg";
import ActionProvider from "./bot/ActionProvider";
import MessageParser from "./bot/MessageParser";
import "./Chat.css";
import "./ChatCustom.css";
import {
    clientIdHandlerFunc,
    setChatBot, setIsBookNowClicked, setShowBotIcon,
    setShowCountDown, setShowPleaseWait
} from "./ReduxStore/action";

import BotConfig from "./data/ChatbotConfig";
import LanguageStore from "./data/LanguageStore";
import Logger from "./Methods/Logger";
import LanguageDataLoader from "./UI/LanguageDataLoader";
/*NOTE: - this component is main component, It contains to chatBot.
Based on  local showChatbot state , chatBot shows up
if showChatbot's value is true
*/

export let selectLanguageText = "";

function App(props) {

    const dispatch = useDispatch();

    const [timeOutText, setTimeOutText] = useState([]);

    const [botText, setBotText] = useState(LanguageStore.defaultBookingText);
    const [intervalID, setIntervalID] = useState(null);
    const [startBookNowAnimation, setStartBookNowAnimation] = useState(false);
    // const [isLanguageFetched, setIsLanguageFetched] = useState(false);
    const [reduxCountDownMessage, setReduxCountDownMessage] = useState(null);


    const [isGotErrorLanguage, setIsGotErrorLanguage] = useState(false);

    //Showing the animated book now
    useEffect(() => {
        if (startBookNowAnimation) {
            let maxIndex = LanguageStore.bookingText.length;
            let currentIndex = 0;
            setBotText(LanguageStore.bookingText[currentIndex]);

            if (maxIndex > 1) {
                let interval2 = setInterval(() => {
                    if (currentIndex < maxIndex) {
                        currentIndex++;
                    }
                    if (currentIndex >= maxIndex) {
                        currentIndex = 0;
                    }
                    setBotText(LanguageStore.bookingText[currentIndex])
                    if (intervalID) { clearInterval(intervalID); }
                    setIntervalID(interval2);
                }, 3000);
            }
        }
    }, [startBookNowAnimation]);

    const [leftCountDown, setLeftCountDown] = useState(null);
    const [timeout, settimeout] = useState(null);
    const [countdownInterval, setCountdownInterval] = useState(null);

    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        // console.log("ViewPort__Height", window.innerHeight)
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    const reduxState = useSelector((state) => state.Reducer);
    const { primaryColor, bannerTitle, homepageIcon } = useSelector((state) => state.Theme);
    const { showChatBot, showBotIcon, showCountDown,connectionStatus,iswebSocketConnected, countDownMessage, countDownTime, staticData, conversationId, isBookNowButtonClicked, showPleaseWait,lastResponseFromBackend } =
        useSelector((state) => state.Reducer);

    useEffect(() => {
        setReduxCountDownMessage(countDownMessage)
    }, [countDownMessage])

    useEffect(() => {
        if (staticData) {

            let data =
                staticData &&
                staticData.inactive_conversation_countdown_message.split("{}");
            // console.log("InActive Message", data)
            setTimeOutText([...data]);
        }
    }, [staticData]);

    const sessionTimeoutFunction = (downTime) => {
        const delay = 1000 * 1;
        settimeout(
            setTimeout(() => {
                let countDown = downTime - 1;
                // setTimeoutModalOpen(true);
                setLeftCountDown(countDown);
                clearTimeout(timeout);
                clearInterval(countdownInterval);
                setCountdownInterval(
                    setInterval(() => {
                        if (countDown > 0) {
                            setLeftCountDown(--countDown);
                        } else {
                            clearTimeout(timeout);
                            clearInterval(countdownInterval);
                            // dispatch(webBotTimeOutFunc(false));
                            // handleLogout(true);
                        }
                    }, 1000)
                );
            }, delay)
        );
    };

    useEffect(() => {
        if (showCountDown) {
            // timer()
            sessionTimeoutFunction(countDownTime);
        } else {
            // console.log("showCountDown---",showCountDown)

            clearTimeout(timeout);
            clearInterval(countdownInterval);
        }
        if (countDownTime) {
            setLeftCountDown(countDownTime);
        }
    }, [showCountDown, countDownTime]);

    useEffect(() => {
        if (leftCountDown === 0) {
            // console.log("leftCountDown",leftCountDown)
            clearTimeout(timeout);
            clearInterval(countdownInterval);
            dispatch(setShowCountDown(false));
        }
    }, [leftCountDown]);

    useEffect(() => {
        dispatch(clientIdHandlerFunc(props.webbot_uid));
        // Abhishek: there have been some challenge in hooking into button click event. For now setting websocket connection ON by default.
        dispatch(setIsBookNowClicked(true))
        return () => {
        };
    }, []);

    // show please wait widget.
    useEffect(() => {
        setShowPleaseWait(true);

        return () => {
        }
    }, [showPleaseWait])

    // NOTe:- here we are toggling the display property of CSS
    const toggleChatbotfunc = async () => {
        setStartBookNowAnimation(true);

        let bot = document.getElementsByClassName("vengage-chatbot-content");
        let closeIcon = document.getElementsByClassName("vengage-chatbot-icon ");

        if (showChatBot == "") {
            dispatch(setShowBotIcon(""));
            dispatch(setChatBot("none"));
        } else {
            bot[0].style.display = "none";
            closeIcon[0].style.display = "";
        }

        //Sending the log to backend

        let data = {
            uid: conversationId ? conversationId : null,
            event: "CHATBOT CLOSED",
            log_type: "INFO",
            data: "Clicked on the close icon",
            source: "USER"
        };
        await Logger(data)
            .then((response) => response.json())
            .catch((error) => {
                console.log(error);
            });
    };





    const toggleBotIcon = async () => {
        // logger.customerLogger('info', "hello world")
        if (botText.toLocaleLowerCase().includes('loading')) return

        // if(!staticData) return
        clearInterval(intervalID);
        setStartBookNowAnimation(false);
        let bot = document.getElementsByClassName("vengage-chatbot-content");
        let closeIcon = document.getElementsByClassName("vengage-chatbot-icon ");

        // Storing the booknow button's status 
        dispatch(setIsBookNowClicked(true))

        if (showBotIcon == "") {
            // console.log("bot[0].style.display",bot[0].style.display)
            dispatch(setShowBotIcon("none"));
            dispatch(setChatBot(""));

        } else {
            setShowBotIcon("");
            setChatBot("none");

        }

        //Sending the log to backend

        let data = {
            uid: conversationId ? conversationId : null,
            event: "CHATBOT OPENED",
            log_type: "INFO",
            data: "Clicked on the bot to start the chat",
            source: "USER"
        };
        await Logger(data)
            .then((response) => response.json())
            .catch((error) => {
                console.log(error);
            });


    };

    //This validator suggestion came from the Author
    const validator = (input) => {
        // allow number to go through
        if (isNaN(input) === false) return true;

        if (input.length > 1)
            return true;
        return false;
    };


    return (
        <LanguageDataLoader webbot_uid={props.webbot_uid} setStartBookNowAnimation={setStartBookNowAnimation}>
            <div className="App">
                <div className="app-chatbot-container">
                    {LanguageStore.languages.length > 0 && <div
                        className="app-chatbot-content  vengage-chatbot-content"
                        style={{ display: showChatBot }}
                    >
                        {/* Chatbot is a component which is imported from  react-chatbot-kit   */}
                        <Chatbot
                            config={BotConfig.getConfig(props, primaryColor, bannerTitle)}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                            validator={validator}
                        />
                        <div>
                            {lastResponseFromBackend.type!=="SHOW_IMAGING_CENTER_DATEPICKER" && showCountDown && reduxCountDownMessage === null && (
                                <Fragment>
                                    <p className="app__noResponse__error">{`${timeOutText[0]} ${leftCountDown} ${timeOutText[1]}`}</p>
                                </Fragment>
                            )}
                            {lastResponseFromBackend.type!=="SHOW_IMAGING_CENTER_DATEPICKER" && showCountDown &&  connectionStatus=="" && reduxCountDownMessage !== null && (
                                <Fragment>
                                    <p style={{ backgroundColor: "yellow", padding: "5px" }} className="app__noResponse__error">{`${countDownMessage}`}</p>
                                </Fragment>
                            )}
                            {connectionStatus!="" && iswebSocketConnected && (
                                <Fragment>
                                    <p style={{ backgroundColor: "yellow", padding: "5px" }} className="app__noResponse__error">{staticData?.internet_connection_issue?.offline_text_at_the_bottom?staticData?.internet_connection_issue?.offline_text_at_the_bottom:"Offline: Please check your internet connection."}</p>
                                </Fragment>
                            )}
                            {/*{showNetWorkConnection()}*/}
                            <button
                                className="app-chatbot-button hide__closeIcon "
                                onClick={toggleChatbotfunc}
                            >
                                <CloseButtonIcon style={{ width: "38%" }} />
                            </button>
                        </div>
                        <div>{
                            showPleaseWait && <Fragment><p>Please wait...</p></Fragment>
                        }</div>
                    </div>}

                    <div
                        className="app-chatbot-button vengage-chatbot-icon"
                        onClick={toggleBotIcon}
                        style={{
                            display: showBotIcon,
                        }}
                    >
                        <div style={{ width: "60px", height: "60px" }}>
                            <img
                                src={homepageIcon != null ? `data:image/jpeg;base64,${homepageIcon}` : null}
                                className="app-chatbot-button-icon"
                            />
                        </div>
                        {botText && botText.length > 0 && (
                            <div className="app-chatbot-button-title">
                                {isGotErrorLanguage ? <span>Got Error</span> : botText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </LanguageDataLoader>
    );
}

export default App;
