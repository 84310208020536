const initialState = {
primaryColor: null,
primaryTextColor:null,
bannerTitle:null,
homepageIcon:null,
bannerIcon:null,
botUtteranceIcon:null,
activeButtonColor:null,
activeButtonTextColor:null,
inactiveButtonColor:null,
inactiveButtonTextColor:null
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case "CUSTOM_THEME":
      return {
        ...state,
        ... action.payload,
      };
    case "DEFAULT_THEME":
      return {
        ...state,
        ... action.payload,
      };
    default:
      return state;
  }
}
