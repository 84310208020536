import Logger from "../Methods/Logger"
import { setStaticData } from "../ReduxStore/action"
import { API_URL } from "../ConstVariable"

const fetchStaticData = async (dispatch, webbot_uid, language_code) => {
    //Sending the log to backend

    let data = {
        uid: null,
        event: "FETCHING STATIC DATA ",
        log_type: "INFO",
        source: "SYSTEM",
        data: `${API_URL}/api/v1/get-static-data`
    }
    Logger(data).then((response) => response.json()).catch((error) => {

        console.log(error)
    })

    // ********************
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    let request_data = {
        webbot_uid: webbot_uid,
        language_code: language_code
    }
    var request = {
        method: "POST",

        headers: myHeaders,
        body: JSON.stringify(request_data),
        redirect: "follow",
    };
    await fetch(`${API_URL}/api/v1/get-static-data`, request).then(response => response.json()).then((response => {
       const {data,error_code, error_description} = response
        if(parseInt(error_code)===0){

            dispatch(setStaticData(data))
        }else{
            console.log("Error",error_description)
        }

    })).catch((error) => {
        //Sending the log to backend
        console.log(error)

        let data = {
            uid: null,
            event: "FAILED TO FETCH STATIC DATA ",
            data:error.error_description,
            log_type: "ERROR",
            source: "SYSTEM"
        }
        Logger(data).then((response) => response.json()).catch((error) => {

            console.log(error)
        })
    })
}

export default fetchStaticData;