/// This is storage for language data that is received in HOC: LanguageLoader
const LanguageStore = {
    data: [],
    languages: [],
    languageText: [],
    bookingText: [],
    defaultBookingText: "Loading...",
    welcomeMessage:[],

    getDisplayText: () => {
        return LanguageStore.languageText.join(" / ");
    }
}

export default LanguageStore;