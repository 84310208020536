
// window.env.WS_URL
// export const API_URL = process.env.REACT_APP_API_URL;

// export const WS_URL = process.env.REACT_APP_WS_URL;
export const API_URL = window.env.API_URL;
// export const API_URL = process.env.REACT_APP_API_URL;


export const WS_URL = window.env.WS_URL;
// export const WS_URL = process.env.REACT_APP_WS_URL;



export const CONNECTION_STATUS = {CONNECTED:"CONNECTED",CONNECTING:"CONNECTING",DISCONNECTED:"DISCONNECTED"};

export const disableInputEventList=["SINGLE_CHOICE","NONE","HANG_UP","BUTTON_GROUP","DATE_PICKER",
"DOCTORWISE_AVAILABLE_SLOTS","PRESET_DEFAULT","TILES","LIST","CALENDAR","UPLOAD","OVERVIEW","DISABLE_INPUT_FIELD","INPUT","USER_TEXT","CONVERSATION_TERMINATED_BY_CONV_ENGINE","ASK_MOBILE"]

export const SERVER_GENERATED_RESPONSE_CODES = ["CONVERSATION_TERMINATED_BY_CONV_ENGINE","HANG_UP","CONVERSATION_INACTIVE_ALERT"];

export const displayInsuranceTypeText={PVT_INSURANCE:"Insurance No.", MEDICARE:"Medicare No."}


export const referralUploadErrorInfo=" We are unable to process the <b >referral form</b>. Please bring it along with you during your visit to the center."