import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import styles from "./Calendar.module.css";
import CustomToolTip from "../../../UI/CustomButton/ToolTip/CustomToolTip";
import {doctorIdFunc ,setTabInfoForMultiCenter} from '../../../ReduxStore/action'
import { useDispatch } from "react-redux";

const Calendar = (props) => {
const dispatch= useDispatch()
  const [monthStartDate, setMonthStartDate] = useState("");
  useEffect(() => {
    if (props.list == 1 || props.list ) {
      setMonthStartDate(props.monthFirstDayIndex - 1);
    } else {
      setMonthStartDate(props.monthLastDayIndex && props.monthLastDayIndex);
    }
  }, []);


  let weekdayshort = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let dateObject = moment();
  const firstDayOfMonth = () => {
    let firstDay = moment(dateObject).startOf("month").format("d");
    return firstDay;
  };

  let weekdayshortname = weekdayshort.map((day) => {
    return (
      <th style={{verticalAlign: "unset", padding: "5px", background: "none"}} key={day} className={styles.vengage__week__day}>
        {day}
      </th>
    );
  });

  let blanks = [];

  
  const datePickHandler = (data, isSlotAvailable) => {

    console.log("Data*****", data)

    // return 
    // if slot is not available then we are doing nothing 
    if (!isSlotAvailable) {
      return;
    }


    let selected_date = data.date.split("-");
    props.setShowSelectedUserDate(
      `${selected_date[2]}-${selected_date[1]}-${selected_date[0]}`
    );
    // props.setSelectedDate(
    //   `${Number(selected_date[0])}-${Number(selected_date[1])}-${Number(
    //     selected_date[2]
    //   )}`
    // );

    props.setSelectedDate(
      data.date
    );

    if(data?.doctorId){

      dispatch(doctorIdFunc(data?.doctorId))
    }


    // if multi center workflow then store the Center info to 
    if(data?.centers_where_service_available){

      dispatch(setTabInfoForMultiCenter(data?.centers_where_service_available))
    }
    
  };



  

  //First date of the month
  for (let i = 0; i <= monthStartDate; i++) {
    blanks.push(<td className={styles.vengage__calendar__day}>{""}</td>);
  }
  let daysInMonth = [];
  //left day in a month

  const slectedDateStyle = (isAvailable, actualDate) => {
    if (!isAvailable) return "#f2f1f0";
    else if ((actualDate === actualDate) == props.selectedDate) return "yellow";
  };



  for (
    let d = props.startDate, i = 0;
    d <= props.endDate && i < props.datelist.length;
    d++, i++
  ) {
    let actualDate = `${Number(props.year)}-${Number(props.month + 1)}-${Number(
      d
    )}`;
    // let modifiedDate= moment(`${Number(d)}-${Number(props.month + 1)}-${Number(props.year)}`).format("YYYY-DD-MM")
    let modifiedDate= props.selectedDate.split('-')
    modifiedDate=`${Number(modifiedDate[0])}-${Number(modifiedDate[1])}-${Number(modifiedDate[2])}`
    // console.log("🚀 ~ file: Calendar.js:106 ~ Calendar ~ modifiedDate:", modifiedDate)
    // if(modifiedDate===actualDate){

    //   console.log("SELECTED_DATE",modifiedDate, actualDate ,props.selectedDate)
    // }
    let backgroundColor = "white"
    if (props.datelist[i].isAvailable===false)
    {
        backgroundColor="#f2f1f0";
    }
    if (actualDate === modifiedDate){
        backgroundColor="yellow";
    }
    daysInMonth.push(
      <td
        data-tip={!props.datelist[i].isAvailable ? "React-tooltip" : null}
        key={d}
        style={{
          backgroundColor: backgroundColor,
        }}
        className={
          !props.datelist[i].isAvailable
            ? styles.vengage__slot__notAvialable
            : actualDate === modifiedDate
            ? styles.vengage__datelist__date__selected
            : styles.vengage__datelist__date
        }
        onClick={() =>
          datePickHandler(props.datelist[i], props.datelist[i].isAvailable)
        }
      >
        <div
          className={styles.vengage__claendar__date}
          // title={!props.datelist[i].isAvailable && "Not Avialable Slot"}
        >
          {Number(d)}
          {!props.datelist[i].isAvailable && (
            <ReactTooltip
              place="bottom"
              type="light"
              effect="float"
              style={{ background: "none" }}
            >
              <span>No available slot</span>
            </ReactTooltip>
          )}
          {/*<span title="Not available slot">{Number(d)}</span>*/}
          {/*<CustomToolTip text={Number(d)} title={"Not Available"} />*/}
        </div>
      </td>
    );
  }



  var totalSlots = [...blanks, ...daysInMonth];
  let rows = [];
  let cells = [];



  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row); // if index not equal 7 that means not go to next week
    } else {
      rows.push(cells); // when reach next week we contain all td in last week to rows
      cells = []; // empty container
      cells.push(row); // in current loop we still push current row to new container
    }
    if (i === totalSlots.length - 1) {
      // when end loop we add remain date
      rows.push(cells);
    }
  });



  let daysinmonth = rows.map((d, i) => {
    return (
      <tr
        key={uuidv4()}
        style={{ marginTop: "10px" }}
        className="calendar__daysinmonth"
      >
        {d}
      </tr>
    );
  });



  return (
    <div className={styles.vengage__claendar}>
      {" "}
      <table className={styles.vengage__calendar__day} style={{ width: "80%", lineHeight:"normal", margin:"0px" }}>
        <thead>
          <tr>{weekdayshortname}</tr>
        </thead>
        <tbody>{daysinmonth}</tbody>
      </table>
    </div>
  );
};

export default Calendar;
