import React, { Fragment, useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Customdatepicker from "../DatePicker/Customdatepicker";
import styles from "./ImagingSlotPicker.module.css";
import { setMultiCenterTabCenterId } from "../../../ReduxStore/action";
import { useSelector, useDispatch } from "react-redux";
// import Calendar from "../CustomCalendar/Calendar";
import TimePickerCalendar from '../Calendar/index.jsx'
import { data } from '../../../languageSelectiontext';
import styles2 from '../imagingMultiCenterCalendar/ImagingMultiCenterCalendar.module.css'
import CustomButton from "../../../UI/CustomButton/CustomButton.js";
const Container = styled.div`
  width: 100%;
  ${"" /* display:flex; */}
  align-items:center;
  justify-content: center;
  ${"" /* border:1px solid; */}
`;
const TabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${"" /* justify-content:center; */}
  ${"" /* border:1px solid; */}
margin-bottom:5px;
overflow-x: scroll; 
  overflow-y: hidden; 
`;
const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomTabContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 2px;
  font-size: 12px;
`;

const LoadingContainer = styled.div`
width:100%;
justify-content:center;
 align-items:center;
height:200px;
  border:1px solid;
`



const CustomTab = ({ data, onClickHandler, selectedTabId }) => {
    return (
        <div
            className={selectedTabId === data?.center_id ? `${styles2.vengage__selectedTab} ${styles2.vengage__customtab}` : styles2.vengage__customtab}
            onClick={() => onClickHandler(data)}
        >
            {data?.center_location}
        </div>
    );
};

const ImagingSlotPicker = (props) => {

    console.log("ImagingSlotPicker*****", props)

    const dispatch = useDispatch()


    const { centerTabInfoForMultiCenter, selectedCenterIdForMultiCenter, dummyCenterForMultiCenter } = useSelector(state => state.Reducer)
    console.log("🚀 ~ file: ImagingSlotPicker.js:105 ~ ImagingSlotPicker ~ centerTabInfoForMultiCenter:", centerTabInfoForMultiCenter)
    // console.log(
    //   "🚀 ~ file: ImagingSlotPicker.js:63 ~ ImagingSlotPicker ~ props:",
    //   props
    // );
    const [tabOptions, setTabOptions] = useState(
        centerTabInfoForMultiCenter ? centerTabInfoForMultiCenter : []
    );
    console.log("🚀 ~ file: ImagingSlotPicker.js:115 ~ ImagingSlotPicker ~ tabOptions:", tabOptions)

    const [availableSlots, setAvailableSlots] = useState(props?.availableSlots)
    useEffect(() => {
        if (centerTabInfoForMultiCenter && Array.isArray(centerTabInfoForMultiCenter) && centerTabInfoForMultiCenter.length) {
            setTabOptions(centerTabInfoForMultiCenter)
        }

    }, [centerTabInfoForMultiCenter])

    const [isMonthDifferent, setIsMonthDifferent] = useState(false) // no use
    const [centerList, setCenterList] = useState([]) // no use 
    const [disableTabData, setDisableData] = useState(false)


    const [workingDays, setWorkingDays] = useState(props?.workingDays);
    const [selectedCenterId, setSelectedCenterId] = useState(
        centerTabInfoForMultiCenter && Array.isArray(centerTabInfoForMultiCenter) && centerTabInfoForMultiCenter.length ? centerTabInfoForMultiCenter[0].center_id : null
    );

    const [loadingCalendar, setLoadingCalendar] = useState(false);
    console.log("🚀 ~ file: ImagingSlotPicker.js:92 ~ ImagingSlotPicker ~ loadingCalendar:", loadingCalendar)

    const handleAIResponse = (response) => {
        let ai_response = JSON.parse(response.data);
        console.log(
            "🚀 ~ file: ImagingSlotPicker.js:71 ~ handleAIResponse ~ ai_response:",
            ai_response
        );
        // setWorkingDays(ai_response?.working_days);
        // setSelectedTabId(ai_response?.currently_selected_center_id);
        setLoadingCalendar(false);
        setAvailableSlots(ai_response)

        // dispatch(setMultiCenterTabCenterId({center_id:ai_response?.currently_selected_center_id,dummy_center_id:ai_response?.dummy_center_id}))

    };

    const tabSelectionHandler = (data) => {
        setSelectedCenterId(data?.center_id);
        // “event”: “MULTIPLE_CENTER_SELECTION”,
        // “uid”: “f7dc8336-fbdf-4d8a-9068-90e1cb1f5029",
        // “data”: “All Centers”,
        // “center_id”: 100,
        // “message_id”: “98a77ad1-676b-4521-adc6-3598de7038f6”

        console.log("TAB CHNAGED", data)

        let message = {
            event: "GET_AVAILABLE_SLOTS_FOR_IMAGING_MULTI_CENTER_WORKFLOW",
            uid: props.conversationId,
            data: data?.center_location,
            center_id: data?.center_id,
            query_date: props.Date
        };
        props.wsClient.handleUserResponse(message, handleAIResponse);

        setLoadingCalendar(true);

    };
    const disableButton = useRef(false)
    const goBackToCalendar = () => {
        disableButton.current=true;
        props.actionProvider.showResponseDialog({
            type: "USER_TEXT",
            message: `Choose a different day`,
        });
        let message = {
            event: "DENY_SLOT",
            uid: props.conversationId,
            data: 'Choose a different day',
            slot_time: null,
        };
        props.wsClient?.handleUserResponse(message, handleAIResponseGoBackToCalendar);
        
    }

    const handleAIResponseGoBackToCalendar = (webSocketMessage) => {
        let ai_response = JSON.parse(webSocketMessage.data);
        props.actionProvider.showResponseDialog(ai_response);
    };

    return (
        <Fragment>
            <div className={styles2.vengage__showDatePickerWithTab} style={{ display: disableTabData && "none" }}>
                {(centerTabInfoForMultiCenter.length > 1 && selectedCenterIdForMultiCenter >= dummyCenterForMultiCenter) && <>
                    <div className={styles2.vengage__tabs}>



                        {tabOptions?.map((each, index) => (
                            <CustomTab
                                selectedTabId={selectedCenterId}
                                data={each}
                                key={index}
                                onClickHandler={(data) => tabSelectionHandler(data)}
                            />
                        ))}
                    </div>
                </>
                }

                {availableSlots && availableSlots.slots_responses.length > 0 && <div className={!isMonthDifferent ? styles2.vengage__datePicker__oneMonth : styles2.vengage__datePicker} style={{ borderTopLeftRadius: centerList && centerList.length === 1 && "8px,", height: centerList && centerList.length > 1 && "395px" }}  >
                    {loadingCalendar ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "337px" }}>
                        <p className={styles2.vengage__dot_pulse}></p> </div>
                        :

                        <TimePickerCalendar setDisableData={setDisableData} {...props} availableSlots={availableSlots} center_id={availableSlots?.center_id} />
                    }
                </div>}
                {disableButton.current === false && availableSlots && availableSlots.slots_responses.length === 0 && <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        {disableButton.current}
                        <CustomButton
                            text={"Choose another date"}
                            disabled={disableButton.current}
                            clickHandler={(e) => goBackToCalendar()}
                        />
                    </div>
                </div>}

            </div>
        </Fragment>
    );
};

export default ImagingSlotPicker;
