import React, { useState, useEffect } from "react";
import styles from "./Patientscanetype.module.css";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import { useSelector} from "react-redux";

const Patientscanetype = (props) => {
  const {iswebSocketConnected,iswebSocketTryingToReConnect} = useSelector(state=>state.Reducer)

  const [scanDetails, setScanDetails] = useState("");
  useEffect(() => {
    let data = props.updatePatientDetails.patient_details.scan_type.split(" ");

    setScanDetails(
      props.updatePatientDetails.patient_details.scan_type.toUpperCase()
    );
  }, []);

  const inputChangeHandler = (event) => {
    setScanDetails(event.target.value.toUpperCase());
  };

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);

    if (ai_response.type === "PRESET") {
      ai_response.type = "BUTTON_GROUP";
    }

    props.actionProvider.showResponseDialog(ai_response);
  };
  // NOTE:- this handleBtnEvent function does two thing 1. shows the user clicked response 2. send the data to server
  const handleBtnEvent = (option) => {
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: `${scanDetails}`,
    });

    // NOTE:- handleUserResponse method sends the info to server and on the bases of info server send back the info through webSocket message
      let message = {
        event: option.event,
        uid: props.conversationId,
        data: scanDetails,
      };
      props.wsClient?.handleUserResponse(message, handleAIResponse);

  
  };

  return (
    <center>
      <div className={styles.vengage__options} style={{ width: "85%" }}>
        <div className={styles.vengage__overview__block}>
          <div className={styles.vengage__overview__row}>
            <div
              className={styles.vengage__item}
              style={{ padding: "8px 0px 5px 0px" }}
            >
              Scan details
            </div>
            <div
              className={styles.vengage__item}
              style={{ paddingBottom: "5px" }}
            >
              <input
                type="text"
                name="Scan details"
                value={scanDetails}
                className={styles.vengage__inputChange}
                onChange={inputChangeHandler}
                placeholder="Scan details"
                style={{ border: "1px solid lightgrey", fontWeight: "700" }}
              />
            </div>
          </div>

          <div className={styles.vengage__options__container}>
            {props.updatePatientDetails !== "" &&
              props.updatePatientDetails.preset_responses.map((option, key) => {
                return (
                  <CustomButton
                    text={option.text}
                    key={key}
                    disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
                    clickHandler={(e) => handleBtnEvent(option)}
                  />
                );
              })}
          </div>
          <p
            style={{ fontStyle: "italic", fontSize: "13px", color: "#119FDA" }}
          >
            Any change in the <b>scan details</b> will require you to fill all
            the information again.
          </p>
        </div>
      </div>
    </center>
  );
};

export default Patientscanetype;
