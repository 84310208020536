import React from 'react'
import styles from './styles.module.css'
const ConversationTermination = () => {
  return (
    <div  style={{border:"2px solid #129FD9"}}>
    <p>This conversation has ended , because there was no response</p>
    <p>To start again click <span style={{color:"#129FD9"}}> "Restart chat"</span></p>
    
    </div>
  )
}

export default ConversationTermination