import React from "react";
import { useSelector } from "react-redux";

const MyUserChatMessage = (props) => {
    const{primaryColor,primaryTextColor} = useSelector((state) => state.Theme);
  return (
    <div className="react-chatbot-kit-user-chat-message-container">
      <div
        className="react-chatbot-kit-user-chat-message"
        style={{ backgroundColor: primaryColor,color:primaryTextColor }}
      >
        <span> {props?.message}</span>
        <div className="react-chatbot-kit-user-chat-message-arrow" style={{ borderLeft:`12px solid ${primaryColor}`}}></div>
      </div>
    </div>
  );
};

export default MyUserChatMessage;
