// import { Button } from "bootstrap";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshHandlerFunc,
  webBotTimeOutFunc,
} from "../../../ReduxStore/action";

import styles from "./TimeOutNotification.module.css";

const TimeOutNotification = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.Reducer);
  const [timeoutCountdown, setTimeoutCountdown] = useState();
  let timeout;
  let countdownInterval;
  
  useEffect(() => {
    // sessionTimeoutFunction();
    
  }, []);

  useEffect(()=>{
    if(props.captureEventType==="CONVERSATION_TERMINATED_BY_CONV_ENGINE"){
      continueSession()
    }

  },[props.captureEventType])
  
  useEffect(()=>{
  setTimeoutCountdown(props.conversationCloseCountDownTime)
  if(props.conversationCloseCountDownTime){
    sessionTimeoutFunction(props.conversationCloseCountDownTime);

  }
  // sessionTimeoutFunction();


},[props.conversationCloseCountDownTime])

  const sessionTimeoutFunction = (conversationCloseCountDownTime) => {
    const delay = 1000 * 1;
    if (true) {
      timeout = setTimeout(() => {
        let countDown =Number(conversationCloseCountDownTime)-1;
        // setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            clearTimeout(timeout);
            clearInterval(countdownInterval);
            dispatch(webBotTimeOutFunc(false));
            // handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };


  useEffect(()=>{
      if(timeoutCountdown==0){
        // props.wsClient.handle_User_CloseConnectionRequest();
       
        continueSession()

      }
  },[timeoutCountdown])

  const continueSession = () => {
    clearTimeout(timeout);
    clearInterval(countdownInterval);
    dispatch(webBotTimeOutFunc(true));
    props.actionProvider.showResponseDialog({
      type: "RESETMESSAGE",
    });
  };




 

  const TimeOutSessionNotification = () => {
    return (
      <Fragment>
        {timeoutCountdown != 0 && (

          <div>
            <center style={{marginBottom:"5px"}}>
            No input received. 
              
            </center>
            <center  style={{marginBottom:"5px"}}>
            Conversation will end automatically in <span style={{ color: "red" }}>{`${timeoutCountdown} seconds `}</span>{" "}.
            </center>
            <center>
              <button
                onClick={continueSession}
                className={styles.vengage__continue__session}
              >
                 Continue Session
              </button>
            </center>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div className={styles.vengage__timeOutNotification__background}>
      <div className={styles.vengage__timeOutNotification}>
        <div className={styles.vengage__timeOut__message}>
          <TimeOutSessionNotification />
        </div>
      </div>
    </div>
  );
};

export default TimeOutNotification;
