import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshHandlerFunc,
  CenterNameFunc,
  webBotTimeOutFunc,
  setMakeWebSocketConnection,
  setWebsocketConnectionStatus
} from "../../../ReduxStore/action";
import parse from "html-react-parser";
import Logger from "../../../Methods/Logger";
import styles from "./UnknownResponseTypeHandle.module.css";
import LanguageStore from "../../../data/LanguageStore";
const UnknownResponseTypeHandle = (props) => {
  const { language_selection_text, staticData, selectedCenterId } = useSelector(
    (state) => state.Reducer
  );
  const { iswebSocketConnected } = useSelector((state) => state.Reducer);

  const dispatch = useDispatch();

  const handle_Refresh_BtnEvent = (index) => {
    if (index === 0) {
      if (iswebSocketConnected == "DISCONNECTED") {
        dispatch(setMakeWebSocketConnection(false));
        dispatch(refreshHandlerFunc(false));

        props.actionProvider.showResponseDialog({
          type: "RESTART_WITH_NEW_CONNECTION",
        });
      } else {
        let message = {
          event: "CLOSE_CONVERSATION",
          uid: props.conversationId,
        };
        if (props.captureEventType != "HANG_UP") {
          props.wsClient.handleUserResponse(message);
        }
        dispatch(refreshHandlerFunc(true));
        dispatch(setMakeWebSocketConnection(false));
        dispatch(setWebsocketConnectionStatus(false))

        //Restart conversation

        props.actionProvider.showResponseDialog({
          type: "RESTARTCONVERASTION",
          message:
            LanguageStore.data.length > 1
              ? LanguageStore.getDisplayText()
              : LanguageStore.welcomeMessage[0],
        });
      }

      dispatch(CenterNameFunc(""));
      dispatch(webBotTimeOutFunc(false));

      //Sending the log to backend
      let data = {
        uid: props.conversationId,
        event: "RESTARTED CONVERSATION ",
        log_type: "INFO",
        data: "User has restarted the conversation id",
        source: "USER",
      };
      Logger(data)
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(refreshHandlerFunc(false));
      props.actionProvider.showResponseDialog({
        type: "RESETMESSAGE",
      });

      //Sending the log to backend
      let data = {
        uid: props.conversationId,
        event: "DENY TO RESTART",
        log_type: "INFO",
        data: "User has denyed to restart the conversation",
        source: "USER",
      };
      Logger(data)
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const ShowSystemError = ({ centerList }) => {
    if (centerList.length === 1) {
        return <div style={{fontSize:"14px"}}>{parse(centerList[0].message)}</div>;
      }
    if (!selectedCenterId) {
      let maxNoIndex = -1;
      let maxNo = -1;
      for (let i = 0; i < centerList.length; i++) {
        if (parseInt(centerList[i].center_id) > maxNo) {
          maxNo = centerList[i].center_id;
          maxNoIndex = i;
        }
      }
      return <div style={{fontSize:"14px"}}>{parse(centerList[maxNoIndex].message)}</div>;
    }
       else {
        let filteredData = centerList.filter(
          (item) => item.center_id === selectedCenterId
        );
        return <div style={{fontSize:"14px"}}>{parse(filteredData[0].message)}</div>;
      }
    
  };

  return (
    <div className={styles.vengage__reappointment}>
      <div
        className={`${styles.vengage__options}  ${styles.vengage__restartPopUp}`}
      >
        <p className={styles.vengage__restart}>
          {/* {staticData && staticData.response_timeout_message_data.message} */}
          <ShowSystemError centerList={staticData.system_error_message} />
        </p>
      </div>
    </div>
  );
};

export default UnknownResponseTypeHandle;
