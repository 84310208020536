import React, { useEffect, useState } from "react";
import CitySelection from "../CitySelection";
import { useDispatch } from "react-redux";
import { centerListFunc, setRebookAppointment , setTriedToSendMsg} from "../../../ReduxStore/action";

import styles from "./index.module.css";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import { useSelector} from "react-redux";


const BtnOptions = (props) => {
  const { wsClient, conversationId, Client_ID,iswebSocketTryingToReConnect} = useSelector(state=>state.Reducer)

  const dispatch = useDispatch();
  const [showCenter, setShowCenter] = useState(false);
  const [CenterList, setCenterList] = useState(null);

  let botInput = document.getElementsByClassName(
    "react-chatbot-kit-chat-input"
  );
  // NOTE:- handleAIResponse method handle the webSocket message and pass the data to actionProvider's showResponseDialog
  const handleAIResponse = (webSocketMessage) => {

    // console.log("MESSAGE FROM WEBSOCKET", webSocketMessage);
    if (webSocketMessage) {
      // if (botInput.length != 0 && botInput[0].disabled != undefined) {
      //   botInput[0].attributes.placeholder.nodeValue =
      //     "Please select one of the above options";
      //   botInput[0].disabled = true;
      //   // console.log("botInput 2", botInput);
      // }
      let ai_response = JSON.parse(webSocketMessage.data);


      if(ai_response.type==="CONFIRM_RESTART_CHAT"){
        dispatch(setRebookAppointment(ai_response))
        props.actionProvider.showResponseDialog({
          type: "ASK_CONFIRMATION",
          workflow_type:props.workflowType
        });
        return

      }
     
      
      else{

        
        if (
          ai_response.type == "PRESET" &&
          ai_response.preset_responses.length > 0 &&
          ai_response.preset_responses[0].text === "Yes"
          ) {
            ai_response.type = "BUTTON_GROUP";
            ai_response.preset_responses[0]["style"] = {
              color: "#000",
              border: "1px solid #000",
            };
          }
        }
      // NOTE:- this ai_response has a type:"TILES",
      // console.log("ai_response in BtnOption", ai_response);
      // this showResponseDialog method is called from actionProvider component and it'll look for CASE TILES
      // and will run the CitySelection component

      // console.log("BUTTON OPTION", ai_response);
      setCenterList(ai_response.preset_responses);

      dispatch(centerListFunc(ai_response.preset_responses));

      // props.setState((prevState) => ({
      //   ...prevState,
      //   centerList: ai_response.preset_responses,
      // }));
      props.actionProvider.showResponseDialog(ai_response);
      setShowCenter(true);
    } else {
      props.actionProvider.showResponseDialog({ type: "LOADING_MESSAGE" });
    }
  };

  

  //This showButton tells when to show confirmation button
  const [showButton, setShowButton] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  // NOTE:- this handleBtnEvent function does two thing 1. shows the user clicked response 2. send the data to server
  const handleBtnEvent = (option) => {
    setDisableButton(true)
    if (option.event==="RESTART_CHAT"){
        props.actionProvider.showResponseDialog({type:"RELOAD"})
        return;
      }



    if (option.event === "CHANGE_APPOINTMENT_DETAILS" ||option.event==="GP_CHANGE_APPOINTMENT") {


      let initialMessage = {
        event:option.event,
        uid:conversationId,
        ClientId: Client_ID,
      };


      wsClient.handleUserResponse(initialMessage, handleAIResponse);
     
      return;
    }

    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: option?.text? option.text: option.text_in_english,
    });

    // NOTE:- handleUserResponse method sends the info to server and on the bases of info server send back the info through webSocket message
    let message = {
      event: option.event,
      uid: props.conversationId,
      data: option?.text_in_english? option.text_in_english:option.text,
    };
    
      props.wsClient?.handleUserResponse(message, handleAIResponse);
    setShowButton("none");
    //
  };

  return (
    <center className={styles.button__container}>
      <div className={ props.modalityConfirmButton && props.modalityConfirmButton.length>2 ?styles.options:""} style={{ width:props.modalityConfirmButton && props.modalityConfirmButton.length>2 ?"90%":"80%" }}>
        <div
          className={styles.options__container}
          style={{ display: showButton }}
        >
          {props.modalityConfirmButton !== "" &&
            props.modalityConfirmButton.map((option, key) => {
              return (
                <CustomButton
                  key={key}
                  text={option.text}
                  clickHandler={(e) => handleBtnEvent(option)}
                  disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:(props.disableAllButton?props.disableAllButton:disableButton)}
                />
              );
            })}
        </div>
      </div>
    </center>
  );
};

export default BtnOptions;
