import React, { Fragment, useEffect, useState } from "react";
import styles from "./TimeSlot.module.css";
const TimeSlot = (props) => {
  const onChangeHandler = (ID, selectedDateTime, doctor_id) => {
    props.setSelectedSlot(ID);
    props.setSelectedSlotDateTime(selectedDateTime);

    props.setSelectedDoctorId(doctor_id);
  };

  const proceedHandler = () => {
    if (!props.selectedSlot && props.selectedSlot.length == 0) {
      // alert("Hello");
      return;
    }
    props.setIsProceedToAppointment(true);
  };

  return (
    <div className={styles.timeSlot_buttons}>
      <div className={styles.timeSlot}   style={{justifyContent:(props.availableSlots&& props.availableSlots.length===0) && "center",
      padding:(props.availableSlots&& props.availableSlots.length===0) && "10px"
    }}>
        { (!props.availableSlots  ||  (props.availableSlots&& props.availableSlots.length===0)) ? <div className={styles.no_slot_text}> No slots are available at this time
        </div> :
          
          props.availableSlots &&
          props.availableSlots.map((item, index) => {
            let ID = `${item.doctor_id}${item.slot_time}`;
            return (
              <div
                key={ID}
                className={
                  ID == props.selectedSlot ? styles.selected_slot : styles.time
                }
                onClick={() =>
                  onChangeHandler(ID, item.slot_scheduletime, item.doctor_id)
                }
              >
                {item.slot_time}   <span style={{fontSize:"12px"}}>
                {props.timeAmOrPm}
                </span>
              </div>
            );
          })}
      </div>
      {props.availableSlots && props.availableSlots.length>0 &&<div className={styles.buttons}>
        <button
          className={styles.button}
          onClick={proceedHandler}
          style={{fontSize:"13px", paddingRight:"15px", paddingLeft:"15px"}}
          disabled={!props.selectedSlot && props.selectedSlot.length == 0}
        >
          {props.confirm_selection}
        </button>
        <button
          className={styles.button}
          onClick={props.chooseAdifferentDay}
          style={{fontSize:"13px", paddingRight:"15px", paddingLeft:"15px"}}

          // disabled={!props.selectedSlot && props.selectedSlot.length == 0}
        >
          {props.deny_selection}
        </button>
        {/*<div className={styles.button}> Close</div>*/}
      </div>}
    </div>
  );
};

export default TimeSlot;
