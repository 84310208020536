import React, { useState, useEffect, Fragment } from "react";
import styles from "./PatientDetails.module.css";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import {displayInsuranceTypeText} from '../../../ConstVariable'
import { useSelector} from "react-redux";

const PatientDetails = (props) => {

  const [disabledButton, setDisabledButton] = useState(false)
  const {iswebSocketConnected} = useSelector(state=>state.Reducer)
  const [disableInputField, setDisableInputField] = useState(false)

  const [firstnameText, setFirstNameText] = useState("")
  const [patientFirstName, setPatientFirstName] = useState("")

  const [lastnameText, setLastNameText] = useState("")
  const [patientLastName, setPatientLastName] = useState("")

  const [dobText, setDobText] = useState("")
  const [patientDob, setPatientDob] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [emailText, setEmailText] = useState("")
  const [patientEmail, setPatientEmail] = useState("")

  const [mobileText, setMobileText] = useState("")
  const [patientPhoneNo, setPatientPhoneNo] = useState({
    code: "",
    phone_no: "",
  });

  const [insuranceTypeText, setInsuranceTypeText] = useState("")
  const[insuranceType, setInsuranceType] = useState("")

  const [medicareNotext, setMedicareNoText] = useState("")
  const [insuranceNumber, setInsuranceNumber] = useState("")

  const [mobileTypeWarning, setMobileTypeWarning] = useState("")
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState([])
  const [showInsuranceError, setShowInsuranceError] = useState(false)
  const [previousInsuranceType, setPreviousInsuranceType] = useState('')
  const [initialInsuranceNumber, setInitialInsuranceNumber] = useState("")
  
  const [patientDetails, setPatientDetails] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    phone: "",
    emailId: ""
  });

  const [displayWarning, setDisplayWarning] = useState("");

  const [dateError, setDateError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [yearError, setYearError] = useState(false);

  const dobHandler = (e) => {
    setDateError(false);
    setMonthError(false);
    setYearError(false);
    setPatientDob({
      ...patientDob,
      [e.target.name]: e.target.value,
    });
  };

  const phoneNoHandler = (e) => {
    setPatientPhoneNo({
      ...patientPhoneNo,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
   

  //  if(props.workflowType==="GP_APPT"){
  //    setInsuranceNumber(props.updatePatientDetails.patientDetails.insurance_number)
  //    setInsuranceType(props.updatePatientDetails.patientDetails.insurance_type)
  //    setInsuranceTypeOptions(props.updatePatientDetails.patientDetails.insurance_type_options)
  //    setPreviousInsuranceType(props.updatePatientDetails.patientDetails.insurance_type)
  //    setInitialInsuranceNumber(props.updatePatientDetails.patientDetails.insurance_number)
  //  }

   props.updatePatientDetails && props.updatePatientDetails.data_list.map((item, index)=>{
     if(item.preset_response==="firstname"){

      setFirstNameText(item.display_text)
      setPatientDetails(()=>{
        return {
          ...patientDetails,
          name: item.value,

        }
      });
      setPatientFirstName(item.value)


     }else if(item.preset_response==="lastname"){

      setLastNameText(item.display_text)
      setPatientDetails(()=>{
        return {
          ...patientDetails,
          name: item.value,

        }
      });
      setPatientLastName(item.value)


     }else if (item.preset_response==="phone"){

      if (
        item.value != "" &&
        item.value != undefined
      ) {
        let code = item.value.slice(0, 3);
        let phone_no =item.value.slice(3);
        setPatientPhoneNo({
          code: code,
          phone_no: phone_no,
        });
      }
       setMobileText(item.display_text)
      setPatientDetails({
        ...patientDetails,
        phone: item.value,
      });

     }else if (item.preset_response==="dob"){
      if (
        item.value != "" &&
        item.value != undefined
      ) {
        let DOB = item.value.split("-");
        setPatientDob({
          day: DOB[2],
          month: DOB[1],
          year: DOB[0],
        });
      }
       setDobText(item.display_text)
      setPatientDetails({
        ...patientDetails,
        dob: item.value,
      });

     }else if (item.preset_response==="insurance_type"){
       setInsuranceTypeText(item.display_text)
      setInsuranceType(item.value)

     }else if(item.preset_response==="insurance_number"){
       setMedicareNoText(item.display_text)
     setInsuranceNumber(item.value)


     }else if (item.preset_response==="insurance_type_options"){
      setInsuranceTypeOptions(item.value)

     }else if (item.preset_response==="email_id"){
      setEmailText(item.display_text)
      setPatientEmail(item.value)
      setPatientDetails({
        ...patientDetails,
        emailId: item.value,
      });
     }
   })
  if(props.updatePatientDetails){

    setMobileTypeWarning(props.updatePatientDetails.mobile_number_required_format_text)
  }

  }, []);


  

  useEffect(()=>{
    if(insuranceType==="MEDICARE" && (insuranceNumber?.length>11 || insuranceNumber?.length<10 )){
      setShowInsuranceError(true)
    }
    if(insuranceType==="PVT_INSURANCE" && insuranceNumber?.length>0){
      setShowInsuranceError(false)
    }
    if(insuranceType==="CASH" ){
      setShowInsuranceError(false)
    }

  },[insuranceType])

  // console.log("PROPS __ Patientdetails", props);

  const inputChangeHandler = (event) => {
    setPatientDetails({
      ...patientDetails,
      [event.target.name]: event.target.value,
    });
  };

  // console.log("Patients Details", patientDetails);

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);

    props.actionProvider.showResponseDialog(ai_response);
  };

  //This showButton tells when to show confirmation button
  const [showButton, setShowButton] = useState("");

  // NOTE:- this handleBtnEvent function does two thing 1. shows the user clicked response 2. send the data to server
  const handleBtnEvent = (option, key) => {
    //NOTE:- key meaning to check that click button is confirm or not
    if (key == 0) {
      if (
        Number(patientDob.day) == 0 ||
        Number(patientDob.day) > 31 ||
        patientDob.day == ""
      ) {
        setDateError(true);
        return;
      }
      if (
        Number(patientDob.month) == 0 ||
        Number(patientDob.month) > 12 ||
        patientDob.month == ""
      ) {
        setMonthError(true);
        return;
      }
      if (patientDob.year == "") {
        setYearError(true);
        return;
      }

      setDisplayWarning("none");
    }

    setPatientDetails(() => ({
      ...patientDetails,
      dob: `${patientDob.year}-${patientDob.month}-${patientDob.day}`,
    }));

   

    // NOTE:- handleUserResponse method sends the info to server and on the bases of info server send back the info through webSocket message
      let message = {
        event: option.event,
        uid: props.conversationId,
        data: option.text,
        patientDetails: {
          firstname: patientFirstName,
          lastname: patientLastName,
          dob: `${patientDob.year}-${patientDob.month}-${patientDob.day}`,
          phone: `${patientPhoneNo.code}${patientPhoneNo.phone_no}`,
          emailId: patientEmail
        },
      };

    if(props.workflowType==="GP_APPT" && option.event!=="BACK_CONFIRMATION"){

      if(insuranceType==="MEDICARE" && (insuranceNumber?.length<10 ||insuranceNumber?.length >11)){
        setShowInsuranceError(true)
        return ;
        
      }
      if(insuranceType==="PVT_INSURANCE" && insuranceNumber?.length===0){
        setShowInsuranceError(true)
        return ;
        
      }
      message.patientDetails["insurance_number"]=insuranceType==="CASH"?"":insuranceNumber
      message.patientDetails["insurance_type"]=insuranceType

     
    }

    //if we Select the cash then 
    if( option.event==="BACK_CONFIRMATION" ){
      setInsuranceType(previousInsuranceType)
      setInsuranceNumber(initialInsuranceNumber)
      
    }

  

      props.wsClient?.handleUserResponse(message, handleAIResponse);

      props.actionProvider.showResponseDialog({
        type: "USER_TEXT",
        message: option.text,
      });
      setDisabledButton(true)
     

    setShowButton("none");
    setDisableInputField(true)
    //
  };

  const insuranceNumberHandler=(e)=>{
    

    
    if(insuranceType==="MEDICARE"){
      
      if (isNaN(e.target.value) && e.target.value.length>11 && previousInsuranceType!="PVT_INSURANCE"){
        return
      }
      
      else{

        if(e.target.value.length<10  || e.target.value.length>11 ){
          setShowInsuranceError(true)
           setInsuranceNumber(e.target.value)
   
          return
        }else{
          setShowInsuranceError(false)
          
        }
        setInsuranceNumber(e.target.value)
      }
    }else{
      setInsuranceNumber(e.target.value)
      if(e.target.value.length>0){
        setShowInsuranceError(false)
      }

    }
  }

  return (
    <div className={styles.vengage__options}>
      <div className={styles.vengage__overview__block}>
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item}>{firstnameText}</div>
          <div className="">
            <input
              type="text"
              name="firstname"
              value={patientFirstName}
              className={styles.vengage__inputChange}
              onChange={(e)=>setPatientFirstName(e.target.value)}
              placeholder="First Name"
              style={{ border: "1px solid lightgrey" }}
              disabled={disableInputField}

            />
          </div>
        </div>
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item}>{lastnameText}</div>
          <div className="">
            <input
              type="text"
              name="lastname"
              value={patientLastName}
              className={styles.vengage__inputChange}
              onChange={(e)=>setPatientLastName(e.target.value)}
              placeholder="Last Name"
              style={{ border: "1px solid lightgrey" }}
              disabled={disableInputField}

            />
          </div>
        </div>
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__itemDate} style={{ marginTop:"2px"}}>
            {dobText}
          </div>
          <div
            className={styles.vengage__itemDateInput}
            style={{ padding: "8px 0px 5px 0px" }}
          >
            <input
              type="text"
              placeholder="DD"
              name="day"
              maxLength="2"
              className={styles.vengage__inputDate}
              style={{
                // marginRight: "5px",
                border: dateError ? "1px solid red" : "1px solid lightgrey",
              }}
              value={patientDob.day}
              onChange={dobHandler}
              disabled={disableInputField}

            />
            <input
              type="text"
              name="month"
              placeholder="MM"
              maxLength="2"
              className={styles.vengage__inputMonth}
              style={{
                // marginRight: "5px",
                border: monthError ? "1px solid red" : "1px solid lightgrey",
              }}
              value={patientDob.month}
              onChange={dobHandler}
              disabled={disableInputField}

            />
            <input
              type="text"
              name="year"
              placeholder="YYYY"
              maxLength="4"
              className={styles.vengage__inputYear}
              style={{
                // marginRight: "5px",
                border: yearError ? "1px solid red" : "1px solid lightgrey",
              }}
              value={patientDob.year}
              onChange={dobHandler}
              disabled={disableInputField}

            />
          </div>
        </div>
        { (props.workflowType ==="GP_APPT" || insuranceType) &&
         <Fragment>
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item}>{insuranceTypeText}</div>
          <div className={styles.select__insuranceType} style={{width:"52%"}}>
          <select disabled={disableInputField} value={insuranceType} onChange={(e)=>setInsuranceType(e.target.value)} style={{outline:"none", width:"100%", marginTop:"5px", }}>
          {insuranceTypeOptions && insuranceTypeOptions.map(item=>  <option value={item.value} >{item.display_text}</option>)}
           
          </select>
          </div>
        </div>
        {insuranceType!="CASH" && <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item} style={{marginTop:showInsuranceError&& "7px"}} >{displayInsuranceTypeText[insuranceType]}</div>
          <div className="" style={{marginTop:"5px"}}>
            <input
              type="text"
              name="Insurance number"
              value={insuranceType==="CASH"?"":insuranceNumber}
              className={styles.vengage__inputChange}
              onChange={insuranceNumberHandler}
              placeholder="Insurance number"
              style={{ border: "1px solid lightgrey" }}
              disabled={ (disableInputField || insuranceType=="CASH") }
              
            />
            {showInsuranceError &&
              <Fragment>
              {insuranceType==="MEDICARE"?
              <p className={styles.insuranceError}>Medicare no. should be 10 or 11 digit.</p>
              :
              <p className={styles.insuranceError}>Insurance no. should not be empty</p>
              
            }
            </Fragment> 
            
            }
          </div>
        </div>}
        </Fragment>

      }
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item}>{mobileText}</div>
          <div
            className=""
            style={{ display: "flex", padding: "7px 0px 5px 0px" }}
          >
            <input
              text="text"
              name="code"
              placeholder="+61"
              maxLength="3"
              value={patientPhoneNo.code}
              className={styles.vengage__inputCode}
              onChange={phoneNoHandler}
              disabled={disableInputField}
            />
            <input
              type="text"
              name="phone_no"
              value={patientPhoneNo.phone_no}
              // className="inputChange"
              placeholder="Phone No"
              maxLength={props.updatePatientDetails && props.updatePatientDetails.required_mobile_digits}
              onChange={phoneNoHandler}
              className={styles.vengage__inputPhoneNo}
              disabled={disableInputField}
            />
          </div>
        </div>
        <div className={styles.vengage__overview__row}>
          <div className={styles.vengage__item}>{emailText}</div>
          <div className="">
            <input
              type="text"
              name="email_id"
              value={patientEmail}
              className={styles.vengage__inputChange}
              onChange={(e)=>{
                setPatientEmail(e.target.value);
                console.log('Email : ', e.target.value);
              }}
              placeholder="Email ID"
              style={{ border: "1px solid lightgrey" }}
              disabled={disableInputField}

            />
          </div>
        </div>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            justifyItems: "center",
            color: "red",
            paddingTop: "10px",
            fontSize: "14px",
            display: displayWarning,
          }}
        >
          {mobileTypeWarning}
        </p>
        <center>
          <div
            className={styles.vengage__options__container}
            style={{ width: "80%" }}
          >
            {props.updatePatientDetails !== "" &&
              props.updatePatientDetails.preset_responses.map((option, key) => {
                return (
                  <CustomButton
                    key={key}
                    text={option.text}
                    clickHandler={(e) => handleBtnEvent(option, key)}
                    disabled={disabledButton || props.disableAllButton}
                  />
                );
              })}
          </div>
        </center>
      </div>
    </div>
  );
};

export default PatientDetails;
