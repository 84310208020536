import React, { useState, useEffect, Fragment } from "react";
import { ConditionallyRender } from "react-util-kit";
import chatBotIcon from "../../../assets/icons/BotIcon.jpg";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HomeIcon from "@material-ui/icons/Home";
import {
  refreshHandlerFunc,
  setIsCloseIconClickedInMobile,
  setShowBotIcon,
  setChatBot,
  setTriedToSendMsg,
  setConnectionStatus
} from "../../../ReduxStore/action";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseButtonIcon } from "../../../assets/icons/cancel.svg";
import ConnectionManager from "../../../WebSocketService/ConnectionManager";
import Logger from "../../../Methods/Logger";

import "./style.css";
const ChatBotHeader = (prop) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.Reducer);
  const { showChatBot, showBotIcon } = useSelector((state) => state.Reducer);
  const {primaryColor,bannerIcon,bannerTitle,primaryTextColor} = useSelector((state) => state.Theme);
  const {
    conversationId,
    restart_chat_text,
    meet_olivia_text,
    centerName,
    getErrorCode,
    reduxRefreshHandler,
    isWebSocketConnectionRequested,
    wsClient,
    isLanguageSelectionPageRendered,
    connectionStatus,
    iswebSocketConnected,
    internetConnectionConnectingText
  } = useSelector((state) => state.Reducer);
    // console.log("🚀 ~ file: index.jsx ~ line 36 ~ ChatBotHeader ~ isLanguageSelectionPageRendered", isLanguageSelectionPageRendered)

  let networkStatus = prop.get_error_code();

  useEffect(()=>{
    
    // console.log("🚀 ~ file: index.jsx ~ line 44 ~ ChatBotHeader ~ networkStatus", networkStatus)
    if(networkStatus?.error_code=="" && connectionStatus!="I999"){
 setTimeout(() => {
  dispatch(setConnectionStatus(networkStatus?.error_code))
  console.log("setConnectionStatus connected called after 25 sec")
 }, 25000);
  }else{
    dispatch(setConnectionStatus(networkStatus?.error_code)) 
  }

  },[networkStatus?.error_code])

  useEffect(() => {
    if (isWebSocketConnectionRequested) {
      prop.makeWebsocketConnection(true);
    }
  }, [isWebSocketConnectionRequested]);

  // NOTe:- here we are toggling the display property of CSS
  const toggleChatbotfunc = () => {
    let bot = document.getElementsByClassName("vengage-chatbot-content");
    let closeIcon = document.getElementsByClassName("vengage-chatbot-icon ");

    if (showChatBot == "") {
      dispatch(setShowBotIcon(""));
      dispatch(setChatBot("none"));
    } else {
      bot[0].style.display = "none";
      closeIcon[0].style.display = "";
    }

    //Sending the log to backend
    let data = {
      uid: conversationId,
      event: "CHATBOT CLOSED",
      log_type: "INFO",
      data: "Clicked on the close icon",
      source: "USER"
    };
    Logger(data)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      });
  };

  // NOTE:- this component shows the header of chatBot
  const reStartHandler = () => {
    //Sending the log to backend
    let data = {
      uid: conversationId,
      event: "CLICKED ON RESTART ",
      log_type: "INFO",
      data: "User has clicked on the restart button",
      source: "USER",
    };
    Logger(data)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      });

    prop.showResponseDialog({ type: "RELOAD" });
    dispatch(setTriedToSendMsg(null))
    // dispatch(refreshHandlerFunc(true));
  };

  const ShowNetworkStatus = ({ networkStatus }) => {
    let updatedNetworkStatus=networkStatus
    if(connectionStatus!=""){
     updatedNetworkStatus=(networkStatus?.error_code=="" && connectionStatus!="I999")?{ error_code: "I001", description:(internetConnectionConnectingText?internetConnectionConnectingText:"Connecting...")}:networkStatus
    }
    
   return (
      <Fragment>
        {!networkStatus.error_code && (
          <div
            className="websocketConnected "
            style={{ color: "#90EE90" }}
          ></div>
        )}
        {(updatedNetworkStatus.error_code === "E001" ||
          updatedNetworkStatus.error_code === "E002") && (
          <p style={{backgroundColor:"yellow",color:"black", padding:"5px"}}>
            {" "}
            {updatedNetworkStatus.description}
          </p>
        )}
        {(updatedNetworkStatus.error_code === "I999" ||
          updatedNetworkStatus.error_code === "I001") && (
          <p  style={{backgroundColor:"yellow",color:"black", padding:"5px"}} >
            {updatedNetworkStatus.description} <span className="circle-orange"></span>
          </p>
        )}
      </Fragment>
    );
  };

  const CenterName = () => {
    if (centerName) {
      return (
        <Fragment>
          <p className="react-chatbot-kit-chat-header__centerName">
            <span>
              {" "}
              <HomeIcon
                style={{
                  color: "lightgreen",
                  fontSize: "15px",
                  marginRight: "2px",
                }}
              />
            </span>
            {centerName}
          </p>{" "}
        </Fragment>
      );
    } else {
      return "";
    }
  };

  return (
    <div
      className="react-chatbot-kit-chat-header"
      style={{ position: "relative",backgroundColor:primaryColor,color:primaryTextColor}}
    >
      <div className="chatbot-header-content-primary">
        <div className="title__N_close_icon">
          <button className="close__chatbot" onClick={toggleChatbotfunc}>
            &times;
            {/*<CloseButtonIcon style={{width:"65%",}}/>*/}
          </button>
          {!reduxRefreshHandler && wsClient && (
            <div className="chatbot-title">
              <p className="titleName" style={{ margin: "0px 0px 0px 0px",color:primaryTextColor }} >
                {bannerTitle}
              </p>
              <CenterName />
              {restart_chat_text && (
                <div className="restart_buttonContainer">
                  {(!reduxRefreshHandler || true) && (
                    <button
                      className="restartButton"
                      style={{
                        marginTop: centerName != "" ? "0px" : "17px",
                      }}
                      onClick={reStartHandler}
                    >
                      {restart_chat_text}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div>
          <div className="chatbot-icon">
            { iswebSocketConnected  && !isLanguageSelectionPageRendered  && (
              <ShowNetworkStatus networkStatus={networkStatus} />
            )}
            <img src={bannerIcon!=null ?`data:image/jpeg;base64,${bannerIcon}`:null} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionManager(ChatBotHeader);
