import React, { Fragment , useEffect, useState} from "react";
import styles from "./BookingDetails.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { style } from "dom-helpers";
const BookingDetails = ({
  patientDob,
  PatientName,
  DOB,
  emailId,
  Phone,
  ScanType,
  Center,
  AppointmentDate,
  workflowType,
  medicareNo,
  insuranceType,
  DoctorName,
  centerAddress,
  data_list
}) => {
  const OverViewDetail = ({ Name, Value, customStyle }) => {
    
    return (
      <div className={styles.overViewDetail}>
        <div className={styles.overViewDetail__title}>{Name}</div>
        <div
          className={
            Name === "Name" || Name === "Scan type" || Name === "Appointment"
              ? styles.overViewDetail__value_boldFont
              : styles.overViewDetail__value
          }
          style={{...customStyle}}
        >
          {Value}
        </div>
      </div>
    );
  };
  const CenterDetail = ({ Name, Value, customStyle }) => {
    return (
      <div className={styles.centerDetail}>
        <div className={styles.centerDetail__title}>{Name}</div>
        <div
          className={
            Name === "Name" || Name === "Scan type" || Name === "Appointment"
              ? styles.overViewDetail__value_boldFont
              : styles.overViewDetail__value
          }
          style={{...customStyle,
            // display:"flex",
          // alignItems:"center"
          }}
        >
          {Value}
        </div>
      </div>
    );
  };
  
  const CenterAddress = ({ Name, Value, customStyle }) => {
    return (
        <div className={styles.centerDetail} style={{height:"fit-content"}}>
        <div className={styles.centerDetail__title}>{Name}</div>
        <div
          className={
            Name === "Name" || Name === "Scan type" || Name === "Appointment"
              ? styles.overViewDetail__value_boldFont
              : styles.overViewDetail__value
          }
          style={{...customStyle,
            // display:"flex",
          // alignItems:"center"
          }}
        >
          {Value}
        </div>
      </div>
    );
  };
  
  return (
    <div className={styles.bookingDetails}>
      <OverViewDetail Name={PatientName.display_text} Value={PatientName.value} />
      <OverViewDetail Name={patientDob.display_text} Value={patientDob.value} />

      <OverViewDetail Name={Phone.display_text} Value={Phone.value} />
      {emailId?.display_text && <OverViewDetail Name={emailId.display_text} Value={emailId.value} />}
      { (workflowType==="GP_APPT" || insuranceType  ||medicareNo)&&
      <Fragment>
      <OverViewDetail Name={insuranceType.display_text} Value={insuranceType.value} />

      {insuranceType && insuranceType.value.toLowerCase()!="cash" &&  <OverViewDetail Name={medicareNo.display_text} Value={medicareNo.value} />}
      </Fragment>  
    }
      
    {workflowType==="GP_APPT" || DoctorName?

    <OverViewDetail Name={DoctorName.display_text} Value={DoctorName.value} customStyle={{fontWeight:"bold"}} /> :<OverViewDetail Name={ScanType.display_text} Value={ScanType.value} />
    }


      <CenterDetail Name={Center.display_text} Value={Center.value} />
     { <CenterAddress Name={centerAddress.display_text} Value={centerAddress.value}  customStyle={{fontWeight:"bold",fontSize:"13px",paddingTop:"10px",paddingBottom:"10px"}}/>} 

      <OverViewDetail Name={AppointmentDate.display_text} Value={AppointmentDate.value} />
    </div>
  );
};

export default BookingDetails;
