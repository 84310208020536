import React, { useState, useEffect } from 'react'
import Customdatepicker from '../DatePicker/Customdatepicker'
import { setShowCountDown, setCountDownTime } from '../../../ReduxStore/action'
import { useDispatch, } from 'react-redux'
import styles from './CalendarWidget.module.css'
const GPCalendarWidget = (props) => {
    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = useState("")
    const [locationId, setLocationId] = useState("")
    const [workingDays, setWorkingDays] = useState([])
    const [date, setDate] = useState("")
    const [centerList, setCenterList] = useState([])
    const [workflowType, setWorkflowType] = useState("")
    const [isCalendarDataFetching, setIsCalendarDataFetching] = useState(false)
    const [disableTabData, setDisableData] = useState(false)
    const [calendarMsg, setCalendarMsg] = useState("")
    const [isMonthDifferent, setIsMonthDifferent] = useState(false)
    const [disAbleProccedButton, setDisAbleProccedButton] = useState(false)




    const [confirmatrionText, setConfirmationText] = useState("")


    const handleAIResponse = (response) => {
        let ai_response = JSON.parse(response.data)
        //    console.log("handleAIResponse Data", ai_response)
        setDate(ai_response.date)
        setConfirmationText(ai_response.confirm_selection)

        if (ai_response.working_days && ai_response.working_days.length > 0) {
            let month1 = ai_response.working_days[0].date
            month1 = month1.split('-')
            let month2 = ai_response.working_days[ai_response.working_days.length - 1].date
            month2 = month2.split("-")
            if (Number(month2[1]) != Number(month1[1])) {
                setIsMonthDifferent(true)
            } else {
                setIsMonthDifferent(false)
            }

            //            console.log("MONTH",month1, month2)

        }
        setWorkingDays(ai_response.working_days)

        setWorkflowType(ai_response.workflow_type)

        setIsCalendarDataFetching(false)
        if (ai_response.type === "CONVERSATION_INACTIVE_ALERT") {
            dispatch(setCountDownTime(ai_response.terminate_conversation_countdown_in_seconds))
            dispatch(setShowCountDown(true))

            //    props.actionProvider.showResponseDialog(ai_response);
        }
        if (ai_response.message && (ai_response.type != "HANG_UP" && ai_response.type != "CONVERSATION_TERMINATED_BY_CONV_ENGINE")) {
            setCalendarMsg(ai_response.message)
        }

        if (ai_response.message && ai_response.type === "HANG_UP" || ai_response.type === "CONVERSATION_TERMINATED_BY_CONV_ENGINE") {
            props.actionProvider.showResponseDialog(ai_response);
            setDisAbleProccedButton(true)
        }
    }
    //************ */
    useEffect(() => {
        if (Array.isArray(props.centerList)) {

            setCenterList(props.centerList)
            setSelectedTab(props.centerList[0].center_id)
            setLocationId(props.centerList[0].pms_location_id)


        }

    }, [])
    // console.log("locationId", locationId)

    //*******************On every tab selection sending the location id to get calendar ********** */

    useEffect(() => {

        if (locationId) {

            setIsCalendarDataFetching(true)

            let message = {
                event: "FETCH_GP_CALENDAR",
                uid: props.conversationId,
                pms_location_id: locationId,
                center_id: selectedTab,
            };
            props.wsClient.handleUserResponse(message, handleAIResponse);
        }
    }, [locationId])


    //Custom tab button
    const CustomTab = ({ text, id, pms_location_id, center_name }) => {
        return <div title={center_name} className={selectedTab === id ? `${styles.vengage__selectedTab} ${styles.vengage__customtab}` : styles.vengage__customtab}
            onClick={() => {
                setSelectedTab(id)
                setLocationId(pms_location_id)
            }}>{text}</div>
    }
    return (
        <div className={styles.vengage__showDatePickerWithTab} style={{ display: disableTabData && "none" }}>
            <div className={styles.vengage__tabs}>
                {props.captureEventType === "SHOW_TAB_DATEPICKER" &&
                    centerList && centerList.map((item, index) => <CustomTab text={item.center_location} id={item.center_id} pms_location_id={item.pms_location_id} center_name={item.center_name} />)
                }
            </div>
            <div className={!isMonthDifferent ? styles.vengage__datePicker__oneMonth : styles.vengage__datePicker} style={{ borderTopLeftRadius: centerList && centerList.length === 1 && "8px,", height: centerList && centerList.length > 1 && "395px" }}  >
                {isCalendarDataFetching ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "337px" }}>
                    <p className={styles.vengage__dot_pulse}></p> </div>
                    :

                    <Customdatepicker Date={date} workingDays={workingDays} setDisableData={setDisableData}{...props} center_id={selectedTab} workflowType={workflowType} calendarMsg={calendarMsg} compIdentity="tab_component" disableAllButton={disAbleProccedButton} confirmatrionText={confirmatrionText} centerList={centerList} />
                }
            </div>
        </div>
    )
}

export default GPCalendarWidget