export const disableInputBox = (
    captureEventType,
    conversationId,
    wsClient,
    handleAIResponse,
    previousCaptureEventType,
    input_box_placeholder,
    connectionStatus
) => {


    //Input will not allow to enter these key word
    const handleOnChange = (e) => {
        if (["$", "*", "^", "%", "!", "~", ";", ":", "?", "/", ">", "<", ",", "(", ")", "=", "#"].includes(e.key)) return false
    }
    let toDisableInput = document.getElementsByClassName("react-chatbot-kit-chat-input");

    let botInput = document.querySelector("#react_chatbot_kit_input");

    let botInputBox = document.querySelector(".react-chatbot-kit-chat-input");



    // To change the placeholder dynamic we have to use above manipulation 


    let sendButton = document.getElementsByClassName(
        "react-chatbot-kit-chat-btn-send"
    );
    if (captureEventType && captureEventType.length != 0) {

        if (!input_box_placeholder) {
            return
        }


        // let captureEventType =  props.captureEventType

        if (captureEventType === "RESETMESSAGE") {

            captureEventType = previousCaptureEventType
            // console.log("previousCaptureEventType")
        }


        // botInputBox.style.textTransform="lowercase"

        console.log(" props.captureEventType", captureEventType);
        if (captureEventType && captureEventType.length != 0) {
            if (
                captureEventType != "SINGLE_CHOICE" &&
                captureEventType != "NONE" &&
                captureEventType != "HANG_UP" &&
                captureEventType != "BUTTON_GROUP" &&
                captureEventType != "DATE_PICKER" &&
                captureEventType != "SHOW_IMAGING_CENTER_DATEPICKER" &&
                captureEventType != "IMAGING_SLOT_SELECTION" &&
                captureEventType != "DOCTORWISE_AVAILABLE_SLOTS" &&
                captureEventType != "PRESET_DEFAULT" &&
                captureEventType != "TILES" &&
                captureEventType != "LIST" &&
                captureEventType != "CALENDAR" &&
                captureEventType != "UPLOAD" &&
                captureEventType != "OVERVIEW" &&
                captureEventType != "DISABLE_INPUT_FIELD" &&
                captureEventType != "INPUT" &&
                captureEventType != "USER_TEXT" &&
                captureEventType != "CONVERSATION_TERMINATED_BY_CONV_ENGINE" &&
                captureEventType != "SHOW_TAB_DATEPICKER" &&
                captureEventType != "SINGLE_LANGUAGE_OVERVIEW" &&
                captureEventType != "DOCTOR_LIST" && // Disabled the input box when we get the doctors list
                !connectionStatus) {
                if (toDisableInput && toDisableInput.length != 0) {
                    toDisableInput[0].disabled = false;
                    toDisableInput[0].onkeypress = handleOnChange
                }

                if (botInput) {

                    botInput.setAttribute('placeholder', input_box_placeholder)


                    if (captureEventType === "GP_ASK_MOBILE" || captureEventType === "ASK_MOBILE") {

                    } else if (captureEventType === "ASK_NAME") {
                        // botInputBox.style.textTransform="capitalize"
                        botInput.setAttribute('type', 'text')

                    }

                    else {
                        botInput.setAttribute("type", 'text')

                    }
                }
                if (sendButton.length != 0 && sendButton[0].disabled != undefined) {
                    sendButton[0].disabled = false;
                }


                // botInputBox.focus();
                botInputBox.style.backgroundColor = "white";
                botInputBox.style.border = "1px solid #119FDA";




            } else {
                if (toDisableInput && toDisableInput.length != 0) {
                    toDisableInput[0].disabled = true;
                }

                // botInputBox.style.backgroundColor = "#EBEBEB";
                botInputBox.style.border = "none";

                if (botInput) {
                    if (captureEventType === "HANG_UP" || captureEventType === "CONVERSATION_TERMINATED_BY_CONV_ENGINE") {
                        let message = {
                            event: "CLOSE_CONVERSATION",
                            uid: conversationId,
                        };

                        wsClient.handleUserResponse(message, handleAIResponse);

                        botInput.setAttribute('placeholder', "")


                    } else if (captureEventType === "PRESET_DEFAULT") {
                        botInput.setAttribute("placeholder", input_box_placeholder)

                    } else if (captureEventType === "DATE_PICKER" || captureEventType === "INPUT" || captureEventType === "SHOW_TAB_DATEPICKER") {

                        botInput.setAttribute("placeholder", "")

                    } else if (captureEventType === "USER_TEXT") {
                        botInput.setAttribute("placeholder", input_box_placeholder)
                    }
                    else if (captureEventType === "CONVERSATION_TERMINATED_BY_CONV_ENGINE") {
                        botInput.setAttribute("placeholder", "")




                    }

                    else {
                        if (botInput) {
                            botInput.setAttribute("placeholder", input_box_placeholder)


                        }
                    }



                }

                if (sendButton.length != 0 && sendButton[0].disabled != undefined) {
                    sendButton[0].disabled = true;
                }
            }
            if(connectionStatus=="E001" || connectionStatus=="E002"){
                wsClient?.handleStopInterval()
            }
        }




    }
};
