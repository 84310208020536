export const setCustomThemeFunc = (themeData) => {
  console.log(themeData);
  return {
    type: "CUSTOM_THEME",
    payload: {
      primaryColor: themeData.primary_color,
      primaryTextColor: themeData.primary_text_color,
      bannerTitle: themeData.banner_title,
      homepageIcon: themeData.homepage_icon,
      bannerIcon: themeData.banner_icon,
      botUtteranceIcon: themeData.bot_utterance_icon,
      activeButtonColor:themeData.active_button_color,
      activeButtonTextColor:themeData.active_button_text_color,
      inactiveButtonColor:themeData.inactive_button_color,
      inactiveButtonTextColor:themeData.inactive_button_text_color
    },
  };
};
export const setDefaultThemefunc = (themeData) => {
  return { type: "DEFAULT_THEME", payload: themeData };
};
