import React, { useState, useEffect } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import IconButton from "@mui/material/IconButton";
import styles from "./index.module.css";
import { CenterNameFunc, doctorIdFunc, setTriedToSendMsg } from "../../../ReduxStore/action";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "material-ui-search-bar";
import NoDataFound from "../../../assets/img/NoDataFound.png";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import DoctorSelection from "./doctorSelection/DoctorSelection";
import CenterSelection from "./centerSelection/CenterSelection";

const CitySelection = (props) => {
  const [isShowDoctorList, setIsShowDoctorList] = useState("")
  const { connectionStatus,iswebSocketTryingToReConnect} = useSelector((state) => state.Reducer);

  useEffect(()=>{

    setIsShowDoctorList( props.captureEventType)
  },[])


  const [centerList, setCenterList] = useState([]);

  useEffect(() => {
    if (props.centerList) {
      setCenterList(() => [...props.centerList]);
    }
  }, [props.centerList]);
  //search bar functionality

  const dispatch = useDispatch();

  const [showCenter, setShowCenter] = useState("");

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);
    if (ai_response.type === "PRESET") {
      ai_response.type = "BUTTON_GROUP";
    }

    props.actionProvider.showResponseDialog(ai_response);
  };

  const handleBtnEvent = (option) => {
    // NOTE:- this handleCenterSelection send the info of selected center to server
    // NOTE:- after clicking on center Ai Send the Response that response comes through handleAIResponse method which is written above
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: option.text,
    });
    let message = "";
    if (isShowDoctorList==='DOCTOR_LIST') {
      message = {
          event: option.event,
          uid: props.conversationId,
          doctor_name: option.text,
          data:option.text,
          doctor_pms_id: option.doctorId,
        };
      } else {
        message = {
          event: option.event,
          uid: props.conversationId,
          data: option.text,
          center_id: option.center_id,
        };
      }
      props.wsClient?.handleUserResponse(message, handleAIResponse);

      

    setShowCenter("none");
  };


  return (
    <div
      className={styles.vengage__options}
      style={{
        display: showCenter,
        position: "relative",
      }}
    >
      {props.workflowType && props.workflowType == "GP_APPT" &&isShowDoctorList==='DOCTOR_LIST'  ? (
        <DoctorSelection
          centerlist={centerList}
          handleBtnEvent={iswebSocketTryingToReConnect?()=>{}:(connectionStatus==""?handleBtnEvent:()=>{})}
          disableAllButton={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
          confirm_selection={props.confirm_selection}
          placeholder_text={props.doctor_list_filter_placeholder_text}
          scrollIntoView={ props.scrollIntoView}
        />
      ):

      <CenterSelection
      centerlist={centerList}
      handleBtnEvent={iswebSocketTryingToReConnect?()=>{}:(connectionStatus==""?handleBtnEvent:()=>{})}
      disableAllButton={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
    />
    
    }
      
    </div>
  );
};

export default CitySelection;
