import {combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension"; // use this for debugging redux store on browser.

import reducer from "./reducer";
import themeReducer from "./ThemeProvider/theme.reducer";

const rootReducer = combineReducers({
    Reducer:reducer,
    Theme: themeReducer,
  });

export default createStore(rootReducer, composeWithDevTools()); // initialize store
