import { SERVER_GENERATED_RESPONSE_CODES } from "../ConstVariable";

class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }

    botInput = document.getElementsByClassName("react-chatbot-kit-chat-input");

    // NOTE:- After typing by a user, This handleAIResponse method handle the messgage which is coming through webSocket
    handleAIResponse = (webSocketMessage, lastResponseFromAPI) => {
        let ai_response = JSON.parse(webSocketMessage.data);
        // let lastResponseFromAPI = this.state.wsClient

        if(SERVER_GENERATED_RESPONSE_CODES.includes(ai_response.type)===false){
        if (lastResponseFromAPI?.message_id === ai_response.message_id) {
            return
        }
    }

        if (
            ai_response.type == "PRESET" &&
            ai_response.preset_responses.length > 0 &&
            ai_response.preset_responses[0].text === "Yes"
        ) {
            ai_response.type = "BUTTON_GROUP";
            ai_response.preset_responses[0]["style"] = {
                color: "#000",
                border: "1px solid #000",
            };
        }


        this.actionProvider.showResponseDialog(ai_response);
    };

    // NOTE:- All the message which user types pass through parse method
    parse = (message) => {
        // let messages = this.state.messages.pop();

        const wsClient = this.state.wsClient;
        const conversationId = this.state.conversationId;

        /*NOTE: - when user type the ans after that this message goes to server with event and then handleAIResponse function runs
          and shows the AI response   */

        if (this.state.Switch_Input === "ASK_NAME") {
        let Message = {
            event: "USER_NAME",
            uid: conversationId,
            data: message,
            };

            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Switch_Input === "DATE_PICKER_IMAGING_MULTI_CENTER") {
            let Message = {
                event: "AVAILABLE_SLOTS_IMAGING_MULTI_CENTER",
                uid: conversationId,
                data: message,
            };

            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Switch_Input === "PATIENT_DEMOGRAPHY") {
            let Message = {
                event: "GP_USER_NAME",
                uid: conversationId,
                data: message,
            };

            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Mobile_No === "ASK_MOBILE") {
            let Message = {
                event: "USER_MOBILE",
                uid: conversationId,
                data: message,
            };
            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Mobile_No === "GP_ASK_MOBILE") {
            let Message = {
                event: "GP_USER_MOBILE",
                uid: conversationId,
                data: message,
            };
            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Switch_Input === "IMG_EREFERAL_ASK_DOB") {
            let Message = {
                event: "IMG_EREFERAL_VERIFY_DOB",
                uid: conversationId,
                data: message,
            };
            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else if (this.state.Switch_Input === "ASK_MOBILE") {
            let Message = {
                event: "VALIDATE_USER_MOBILE",
                uid: conversationId,
                data: message,
            };
            wsClient.handleUserResponse(Message, this.handleAIResponse);

        } else {
            let Message = {
                event: "USER_TEXT",
                uid: conversationId,
                data: message,
            };
            wsClient.handleUserResponse(Message, this.handleAIResponse);

        }
    };
}

export default MessageParser;
