import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    refreshHandlerFunc,
    CenterNameFunc,
    webBotTimeOutFunc,
    setMakeWebSocketConnection,
    setWebsocketConnectionStatus
} from "../../../ReduxStore/action";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import Logger from "../../../Methods/Logger";

import styles from "./reappointment.module.css";
import LanguageStore from "../../../data/LanguageStore";
const Reappointment = (props) => {
    const { language_selection_text, staticData } = useSelector((state) => state.Reducer);
    const { iswebSocketConnected } = useSelector(state => state.Reducer)
    const {primaryColor} = useSelector((state) => state.Theme);
    const dispatch = useDispatch();

    const handle_Refresh_BtnEvent = (index) => {
        if (index === 0) {
            if (
                iswebSocketConnected == "DISCONNECTED"
            ) {
                // let message = {
                //   event: "CLOSE_CONVERSATION",
                //   uid: props.conversationId,
                // };
                // props.wsClient.handleUserResponse(message);
                dispatch(setMakeWebSocketConnection(false))
                dispatch(refreshHandlerFunc(false));

                props.actionProvider.showResponseDialog({
                    type: "RESTART_WITH_NEW_CONNECTION",
                });



            } else {
                let message = {
                    event: "CLOSE_CONVERSATION",
                    uid: props.conversationId,
                };
                if (props.captureEventType != "HANG_UP") {
                    props.wsClient.handleUserResponse(message);
                }
                dispatch(refreshHandlerFunc(true));
                dispatch(setMakeWebSocketConnection(false))
                dispatch(setWebsocketConnectionStatus(false))


                //Restart conversation

                props.actionProvider.showResponseDialog({
                    type: "RESTARTCONVERASTION",
                    message: LanguageStore.data.length>1?LanguageStore.getDisplayText() : LanguageStore.welcomeMessage[0]

                });





            }

            dispatch(CenterNameFunc(""));
            dispatch(webBotTimeOutFunc(false));


            //Sending the log to backend
            let data = {
                uid: props.conversationId,
                event: "RESTARTED CONVERSATION ",
                log_type: "INFO",
                data: "User has restarted the conversation id",
                source: "USER"
            }
            Logger(data).then((response) => response.json()).catch((error) => {

                console.log(error)
            })



        } else {
            dispatch(refreshHandlerFunc(false));
            props.actionProvider.showResponseDialog({
                type: "RESETMESSAGE",
            });


            //Sending the log to backend
            let data = {
                uid: props.conversationId,
                event: "DENY TO RESTART",
                log_type: "INFO",
                data: "User has denyed to restart the conversation",
                source: "USER"
            }
            Logger(data).then((response) => response.json()).catch((error) => {

                console.log(error)
            })
        }
    };

    return (
        <div className={styles.vengage__reappointment}>
            <div
                className={`${styles.vengage__options}  ${styles.vengage__restartPopUp}`} style={{border:`2px solid ${primaryColor}`}}
            >
                <p className={styles.vengage__restart}>
                    {staticData && staticData.restart_conversation_message.message}
                </p>
                <div className={styles.vengage__options__container}>
                    {staticData && staticData.restart_conversation_message.preset_responses.map((item, index) => <CustomButton
                        text={item.text}
                        disabled={false}
                        clickHandler={(e) => handle_Refresh_BtnEvent(index)}
                    />)}
                </div>
            </div>
        </div>
    );
};

export default Reappointment;
