import { API_URL } from "../ConstVariable";

const Logger = async(request_data)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    var request = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(request_data),
        redirect: "follow",
      };
 
 
      let response = await fetch(`${API_URL}/api/v1/logger`, request)

      return response

}

export default Logger