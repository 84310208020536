import React, { useState, useEffect } from "react";
import styles from "./WebSocketError.module.css";
import CustomButton from "../../../UI/CustomButton/CustomButton";
import {
  refreshHandlerFunc,
  CenterNameFunc,
  setMakeWebSocketConnection,
  setReportResponseDelay,
  setWebsocketConnectionStatus
} from "../../../ReduxStore/action";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import axios from "axios";
import { API_URL } from "../../../ConstVariable";
import LanguageStore from "../../../data/LanguageStore";
import Logger from "../../../Methods/Logger";

const ErrorPage = (props) => {
  const {
    language_selection_text,
    staticData,
    Client_ID,
    selected_language,
    conversationId,
    Error,
    triedToSendMsg,
    iswebSocketTryingToReConnect
  } = useSelector((state) => state.Reducer);

  const reportDelayMessage = (request_data) => {
    dispatch(setReportResponseDelay(false));
    axios({
      method: "post",
      url: `${API_URL}/api/v1/error`,
      data: request_data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("Error reported successfully", response.data);
      })
      .catch((error) => {
        console.error("Failed to report error");
      });
  };

  useEffect(() => {
    
     let  data = {
        conversation_id: conversationId,
        error_code: "REPONSE_WAIT_TIMEOUT_ERROR",
        expected_response_for_event: triedToSendMsg?.event
          ? triedToSendMsg?.event
          : "",
      };

    reportDelayMessage(data);

    let requestData = {
      uid: conversationId,
      event: "REPONSE_WAIT_TIMEOUT_ERROR",
      log_type: "ERROR",
      data: "Failed to receive system response.",
      source: "SYSTEM",
    };
    Logger(requestData)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
      });

  }, []);

  //   const reduxState = useSelector((state) => state.Reducer);
  const dispatch = useDispatch();
  const [errorData, setErrorData] = useState([]);

  useEffect(() => {
    // if (staticData) {
    //   let data = staticData.system_error_message.message.split("<br>");
    // }
  }, [staticData]);

  const handleBtnEvent = () => {
    // let eventType = "CLOSE_CONVERSATION";
    let initialMessage = {
      event: "CLOSE_CONVERSATION",
      uid: props.conversationId,
    };
    props.wsClient.handleUserResponse(initialMessage);

    dispatch(refreshHandlerFunc(true));
    dispatch(setMakeWebSocketConnection(false));
    dispatch(setWebsocketConnectionStatus(false))

    //Restart conversation
    props.actionProvider.showResponseDialog({
      type: "RESTARTCONVERASTION",
      message:
        LanguageStore.data.length > 1
          ? LanguageStore.getDisplayText()
          : LanguageStore.welcomeMessage[0],
    });

    // dispatch(refreshHandlerFunc(false));
    dispatch(CenterNameFunc(""));

    props.wsClient.resetResponseDelayErrorVariables();
  };

  const ReferralFormUploadErrorInfo = () => {
    return (
      <div
        className={styles.vengage__options}
        style={{
          border: "1px solid lightgrey",
          paddingTop: "10px",
          paddingBottom: "20px",
        }}
      >
        <p>
          We are unable to process the <b>referral form</b>.
        </p>
        <p style={{marginBottom:"0px"}}>
          Please visit to our center with the <b>referral form </b>on appointment date
        </p>
        OR
        <p style={{marginTop:"0px"}} >
          Click on <b>Restart </b> to try once again
        </p>
        <div className={styles.vengage__options__container}>
          <CustomButton text={"Restart"} clickHandler={handleBtnEvent} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.vengage__reappointment}>
      {Error?.error === "REFERRAL_UPLOAD_ERROR" ? (
        <ReferralFormUploadErrorInfo />
      ) : (
        <div
          className={styles.vengage__options}
          style={{ border: "1px solid lightgray", padding: "10px" }}
        >
          <p>{staticData && parse(staticData.system_error_message.message)}</p>

          <div className={styles.vengage__options__container}>
            {staticData &&
              staticData.system_error_message.preset_responses.map(
                (item, index) => (
                  <CustomButton
                    text={item.text}
                    disabled={iswebSocketTryingToReConnect?iswebSocketTryingToReConnect:props.disableAllButton}
                    clickHandler={handleBtnEvent}
                  />
                )
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
