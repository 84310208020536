import React, { useState, useEffect } from 'react'
import LanguageStore from '../data/LanguageStore';
import Logger from '../Methods/Logger';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLanguageFunc } from '../ReduxStore/action';
import fetchStaticData from '../data/StaticData';
import { API_URL } from '../ConstVariable';
import { setCustomThemeFunc } from '../ReduxStore/ThemeProvider/theme.action';
/// This is a high order component that fetches supported for languages for the webbot.
const LanguageDataLoader = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        //Sending the log to backend
        let data = {
            uid: null,
            event: "FETCHING LANGUAGE",
            log_type: "INFO",
            source: "USER",

            data: `${API_URL}/api/v1/webbot-supported-languages-for-client/${props.webbot_uid}`,
        };
        Logger(data).then((response) => response.json()).catch((error) => { console.log(error); });
        // Fetch languages
        fetch(
            `${API_URL}/api/v1/webbot-supported-languages-for-client/${props.webbot_uid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        ).then((response) => response.json()).then((res) => {
            res?.language_options.forEach(element => {

                LanguageStore.languages.push(element.language);
                LanguageStore.languageText.push(element.message);
                LanguageStore.bookingText.push(element.booking_text);
            });
            let theme_data=setCustomThemeFunc(res?.webbot_theme)
            dispatch(theme_data)
            LanguageStore.data = res?.language_options;
            if(res?.language_options.length===1){
                // set selected language in redux
                dispatch(setSelectedLanguageFunc(res?.language_options[0].language_code))
                fetchStaticData(dispatch, props.webbot_uid,res?.language_options[0].language_code)
            }
            props.setStartBookNowAnimation(true);
        }).catch((error)=>{
            console.log(error)

            let data = {
                uid: null,
                event: "FAILED TO FETCH LANGUAGE ",
                data:error?.error_description,
                log_type: "ERROR",
                source: "USER",
    
                data: `${API_URL}/api/v1/get-static-data`
            }
            Logger(data).then((response) => response.json()).catch((error) => {
    
                console.log(error)
            })
        })

        return () => {

        }
    }, [])

    return (
        <div>{props.children}</div>
    )
}

export default LanguageDataLoader