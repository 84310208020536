import { useEffect, useState } from "react";
import { FadeIn } from "react-anim-kit";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../ConstVariable";
import LanguageStore from "../../../data/LanguageStore";
import Logger from "../../../Methods/Logger";
import {
  refreshHandlerFunc,
  setCountDownTime,
  setIsLanguagePageRendered,
  setMakeWebSocketConnection,
  setOliviaAndRestartText,
  setReportResponseDelay,
  setShowCountDown,
  setShowPleaseWait,
  webBotTimeOutFunc,
  setWebsocketConnectionStatus
} from "../../../ReduxStore/action";
import { disableInputBox } from "../Methods/DisableInputBox";
import Options from "./Options";
import styles from "./Overview.module.css";

//NOTE:- This component runs when chatBot mount
function Overview(props) {
  const {
    webSocketresponse,
    delayResponseReported,
    Client_ID,
    wsClient,
    iswebSocketConnected,
    isWebSocketConnectionRequested,
    reduxRefreshHandler,
    selectedLanguage,
    selected_language,
    isLanguageSelectionPageRendered,
    triedToSendMsg,
    isBookNowButtonClicked,
    sentMsgId,
    lastResponseFromBackend,
    connectionStatus,
    initialWebsocketConnectionEstablished
  } = useSelector((state) => state.Reducer);

  const [appointmentOptions, setAppointmentOptions] = useState([]);

  useEffect(() => {
    /*
    If there is delay of 30 sec in the response then We are again trying to get the response using API / previous sent msg 
    */

    if (delayResponseReported) {
      // alert("delayResponseReported");
      let language_code =
        LanguageStore.length === 1
          ? LanguageStore[0].language_code
          : selected_language;

      if (initialWebsocketConnectionEstablished === true) {
        fetchLastRequestMsgOnReconnect(
          props.conversationId,
          sentMsgId,
          language_code,
          delayResponseReported
        );
      }
    }
  }, [delayResponseReported]);

  useEffect(() => {
    var chatBotInput = document.getElementsByClassName(
      "react-chatbot-kit-chat-input"
    );
    if (chatBotInput && chatBotInput.length != 0) {
      chatBotInput[0].id = "react_chatbot_kit_input";
    }
  }, []);

  const [userLastRequest, setUserLastRequest] = useState("INITIAL");
  const sendLogToBackend = (conversationId, event, data, source, log_type = "INFO") => {
    //Sending the log to backend
    // var dicData = JSON.parse(data)
    let requestData = {
      uid: conversationId,
      event: event,
      log_type: log_type,
      data: data,
      source: source
    };
    Logger(requestData)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
  };

  /* Fetching the last response on reconnect the internet */
  const fetchLastRequestMsgOnReconnect = (
    conversationId,
    last_sent_message_id,
    language_code,
    delayResponseReported
  ) => {
    // alert("Going to hit API ")
    axios({
      method: "GET",
      url: `${API_URL}/api/v1/get-last-response?conversation_id=${conversationId}&message_id=${last_sent_message_id}`,
    })
      .then((response) => {
        const { error_code, data } = response.data;
        if (data?.message_id === last_sent_message_id) {
          // step 1: reconnect the websocket
          // Sending the connected event
          let reconnect_message = {
            event: "CONNECTED",
            uid: props.conversationId,
            ClientId: Client_ID,
            language_code: language_code,
          };

          // ask websocket to send connect message
          if (initialWebsocketConnectionEstablished === true) {
            wsClient.handleUserResponse(reconnect_message, null);
          } else {
            wsClient.handleUserResponse(reconnect_message, handleAIResponse);
          }

          // update reducer
          if (initialWebsocketConnectionEstablished === false) {
            dispatch(setWebsocketConnectionStatus(true));
          }

          props.actionProvider.showResponseDialog({
            type: "SET_WEBSOCKET_TO_STATE",
            data: wsClient,
          });
          dispatch(setReportResponseDelay(false));
          wsClient.resetResponseDelayErrorVariables();

          // step 1: check if the last response over api is same as the last websocket response
          // step 2: if not, process the response.data=>data

          if (lastResponseFromBackend?.message_id !== data?.message_id) {
            props.actionProvider.showResponseDialog(data);
            wsClient.captureLastResponseFromAPI(data);
          }
        }
        else{
          console.log("Message ID: mismatched. Reporting to server as error.")
          sendLogToBackend(props.conversationId, "LAST_RESPONSE_VIA_API", JSON.stringify(data), "SYSTEM","ERROR");
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const dispatch = useDispatch();

  const [startTimerManually, setStartTimerManually] = useState(true);


  // Request for webSocket Connection When we have single language Selection

  useEffect(() => {
    // Collecting the Booking option on the first time there is any
    if (Array.isArray(props.defaultOptions)) {
      setAppointmentOptions(props.defaultOptions);
      dispatch(setIsLanguagePageRendered(false));
    }

    if (
      LanguageStore.data &&
      LanguageStore.data.length === 1 &&
      appointmentOptions.length == 0 &&
      isBookNowButtonClicked
    ) {
      dispatch(setMakeWebSocketConnection(true));
    }
  }, [props.defaultOptions, isBookNowButtonClicked]);

  useEffect(() => {
    // Checking the if we have only one language
    if (LanguageStore.data && LanguageStore.data.length === 1) {
      // On reconnect the webSocket
      /*
      if there is any sentMsgId in the store and conversation id then try to fetch the last response 
      message 

      */
      if (
        props.conversationId &&
        iswebSocketConnected === "CONNECTED" &&
        wsClient &&
        sentMsgId
      ) {
        // alert(iswebSocketConnected);

        if (initialWebsocketConnectionEstablished === true) {
          fetchLastRequestMsgOnReconnect(
          props.conversationId,
          sentMsgId,
          LanguageStore.data[0].language_code
        );
      } else {
        if (iswebSocketConnected == "CONNECTED" && wsClient) {
          let initialMessage = {
            event: "CONNECTED",
            uid: props.conversationId,
            ClientId: Client_ID,
            language_code: LanguageStore.data[0].language_code,
          };

          // if reconnected, handleAIResponse=null
          if (initialWebsocketConnectionEstablished === true) {
            wsClient.handleUserResponse(initialMessage, null);
          } else {
            wsClient.handleUserResponse(initialMessage, handleAIResponse);
          }
          if (initialWebsocketConnectionEstablished === false) {
            dispatch(setWebsocketConnectionStatus(true));
          }

          props.actionProvider.showResponseDialog({
            type: "SET_WEBSOCKET_TO_STATE",
            data: wsClient,
          });
        }
      }
      } else {
        // First Time This code will run

        if (iswebSocketConnected == "CONNECTED" && wsClient) {
          let initialMessage = {
            event: "CONNECTED",
            uid: props.conversationId,
            ClientId: Client_ID,
            language_code: LanguageStore.data[0].language_code,
          };

          // if reconnected, handleAIResponse=null
          if (initialWebsocketConnectionEstablished === true) {
            wsClient.handleUserResponse(initialMessage, null);
          } else {
            wsClient.handleUserResponse(initialMessage, handleAIResponse);
          }
          if (initialWebsocketConnectionEstablished === false) {
            dispatch(setWebsocketConnectionStatus(true));
          }

          props.actionProvider.showResponseDialog({
            type: "SET_WEBSOCKET_TO_STATE",
            data: wsClient,
          });
        }
      }
    } else {
      if (
        props.conversationId &&
        iswebSocketConnected === "CONNECTED" &&
        wsClient &&
        sentMsgId &&
        !isLanguageSelectionPageRendered
      ) {
        // alert(`${iswebSocketConnected} multilingual`);

        if (initialWebsocketConnectionEstablished === true) {
          fetchLastRequestMsgOnReconnect(
            props.conversationId,
            sentMsgId,
            selected_language
          );
        }
      } else {
        if (
          iswebSocketConnected == "CONNECTED" &&
          wsClient &&
          !isLanguageSelectionPageRendered
        ) {
          let initialMessage = {
            event: "CONNECTED",
            uid: props.conversationId,
            ClientId: Client_ID,
            language_code: selected_language,
          };

          if (initialWebsocketConnectionEstablished === true) {
            wsClient.handleUserResponse(initialMessage, null);
          } else {
            wsClient.handleUserResponse(initialMessage, handleAIResponse);
          }
          if (initialWebsocketConnectionEstablished === false) {
            dispatch(setWebsocketConnectionStatus(true));
          }

          props.actionProvider.showResponseDialog({
            type: "SET_WEBSOCKET_TO_STATE",
            data: wsClient,
          });
        }


      }
    }
  }, [LanguageStore.data, iswebSocketConnected, wsClient]);

  useEffect(() => {
    let data = {
      meet_olivia_text: props.meet_olivia_text,
      restart_chat_text: props.restart_chat_text,
    };
    dispatch(refreshHandlerFunc(false));
    dispatch(setOliviaAndRestartText(data));
  }, [props.meet_olivia_text, props.restart_chat_text]);

  useEffect(() => {
    dispatch(setCountDownTime(props.conversationCloseCountDownTime));
  }, [props.conversationCloseCountDownTime]);

  useEffect(() => {
    dispatch(setShowCountDown(props.showTheCountDown));
    dispatch(setShowPleaseWait(props.showPleaseWait));
  }, [props.showTheCountDown]);

  //Disabling the input box
  useEffect(() => {

    disableInputBox(
      props.captureEventType,
      props.conversationId,
      props.wsClient,
      handleAIResponse,
      props.previousCaptureEventType,
      props.input_box_placeholder,
      connectionStatus
    );
  }, [props.captureEventType, connectionStatus]);

  // NOTE:- this handleAIResponse function takes the webSocketMessage and shows the message to chatBot Ui

  function handleAIResponse(webSocketMessage, lastResponseFromAPI) {
    let ai_response = JSON.parse(webSocketMessage.data);


    if (LanguageStore.data?.length === 1) {
      if (ai_response.type === "PRESET") {
        LanguageStore.welcomeMessage.push(ai_response.message);

        setAppointmentOptions(ai_response.preset_responses);
        let data = {
          meet_olivia_text: ai_response.meet_olivia_text,
          restart_chat_text: ai_response.restart_chat_text,
        };
        dispatch(setOliviaAndRestartText(data));

        ai_response.type = "SINGLE_LANGUAGE_OVERVIEW";

        props.actionProvider.showResponseDialog(ai_response);

        return;
      }
    } else {
      if (ai_response.type === "PRESET") {
        // alert("Rendered")
        ai_response.type = "PRESET_DEFAULT";
        // return
      }
    }

    // NOTE: - This showResponseDialog method is written into actionProvider component.it stores the airesponse.preset_responses data
    props.actionProvider.showResponseDialog(ai_response);
  }

  const handleUserAction = (data, index) => {
    // NOTE:-This showResponseDialog method shows the user response when he click on the option
    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: data.text,
    });

    let message = {
      event: data.event,
      uid: props.conversationId,
      data: data.text,
    };

    if (initialWebsocketConnectionEstablished === true) {
      props.wsClient.handleUserResponse(message, null);
    } else {
      props.wsClient.handleUserResponse(message, handleAIResponse);
    }
    if (initialWebsocketConnectionEstablished === false) {
      dispatch(setWebsocketConnectionStatus(true));
    }

    setStartTimerManually(false);
    dispatch(webBotTimeOutFunc(true));
  };

  return (
    <div className={styles.vengage__overview}>
      <FadeIn left>
        {/*props.defaultOptions is a local state from config componenet,
        NOTE:- defaultOptions state is initially set from actionprovider component when chatBot render initially

        */}

        <Options
          defaultOptions={appointmentOptions}
          actionProvider={props.actionProvider}
          handleUserAction={handleUserAction}
          conversationId={props.conversationId}
        />
      </FadeIn>
    </div>
  );
}

export default Overview;
