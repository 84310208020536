import { Component } from "react";
import { connect } from "react-redux";
import WebSocketConnection from "./Websocket";
// import { useAuth0, withAuth0 } from "@auth0/auth0-react";
// import {setWebSocketFunc} from '../redux-store/actions';
import { CONNECTION_STATUS, API_URL } from "../ConstVariable";
import Logger from "../Methods/Logger";
import {
    setCountDownMessage,
    setLastResponseFromBackend,
    setReportResponseDelay,
    setSentMsgId,
    setShowCountDown,
    updateCountDownTime,
    setTriedToSendMsg,
    setwebSocket,
    socketReadyStateFunc,
    setWebsocketTryingToReconnectStatus,
} from "../ReduxStore/action";

export default function (ComposedComponent) {
    class ConnectionManager extends Component {
        state = {
            ws_connection_status: null,
            internet_connection_status: CONNECTION_STATUS.CONNECTED,
            internet_connection_status_retries: 0,
            check_internet_connection_interval_call_id: null,
            ws_connection_ref: null,
            client_detail: null,
            interval_time: 30,
            makeWebsocketConnection: false,
            reset_websocket_variable: null,
            timeInterval: 3,
        };

        get_error_code = () => {
            let connectionStatus = {
                error_code: "I999",
                description: "Connecting...",
            };
            if (
                this.state.ws_connection_status === CONNECTION_STATUS.CONNECTED &&
                this.state.internet_connection_status === CONNECTION_STATUS.CONNECTED
            ) {
                connectionStatus = { error_code: "", description: "Connected" };
            } else if (
                this.state.internet_connection_status ===
                CONNECTION_STATUS.DISCONNECTED ||
                this.state.internet_connection_status === CONNECTION_STATUS.CONNECTING
            ) {
                let OfflineTextDesc=this.props.internetConnectionOfflineTextTop?this.props.internetConnectionOfflineTextTop:"Offline"
                connectionStatus = { error_code: "E001", description: OfflineTextDesc};
            } else if (
                this.state.ws_object === null &&
                this.state.ws_connection_status === CONNECTION_STATUS.DISCONNECTED
            ) {
                let OfflineTextDesc=this.props.internetConnectionOfflineTextTop?this.props.internetConnectionOfflineTextTop:"Offline"
                connectionStatus = { error_code: "E002", description:OfflineTextDesc};
            } else if (
                this.state.ws_object === null ||
                this.state.ws_connection_status === CONNECTION_STATUS.CONNECTING
            ) {
                let ConnectingTextDesc=this.props.internetConnectionConnectingText?this.props.internetConnectionConnectingText:"Connecting..." 
                connectionStatus = { error_code: "I001", description:ConnectingTextDesc};
            } else {
                let ConnectingTextDesc=this.props.internetConnectionConnectingText?this.props.internetConnectionConnectingText:"Connecting..." 
                connectionStatus = { error_code: "I999", description: ConnectingTextDesc};
            }
            return connectionStatus;
        };

        // Seting the webSocket connection state
        setWebSocketConnectionStateFunc = (
            web_socket_ref,
            webSocketConnectionState
        ) => {
            let _state = null;

            if (webSocketConnectionState === 1) {
                _state = CONNECTION_STATUS.CONNECTED;
            }

            if (webSocketConnectionState === 2) {
                _state = CONNECTION_STATUS.CONNECTING;
            }

            if (webSocketConnectionState === 3) {
                _state = CONNECTION_STATUS.DISCONNECTED;
            }
            console.log("ws_connection_ref:", _state);
            this.setState({
                ...this.state,
                ws_connection_status: _state,
                ws_connection_ref: web_socket_ref,
                reset_websocket_variable: web_socket_ref,
            });
            this.props.readyStateFunc(_state);
            this.props.setWebSocketFunc(web_socket_ref);
        };

        setWebsocketTryingToReconnectStatus = (status) => {
            this.props.setWebsocketTryingToReconnectStatus(status)
        };

        makeConnection = (value) => {
            this.setState({
                ...this.state,
                makeWebsocketConnection: value,
            });
        };

        componentDidUpdate(prevProps, prevState) {
            //  console.log("PrevProps",prevProps )
            //  console.log("PrevState",prevState )
            if (
                !prevState.makeWebsocketConnection &&
                this.state.makeWebsocketConnection
            ) {
                let ws = new WebSocketConnection(
                    this.setWebSocketConnectionStateFunc,
                    this.props.setShowCountDown,
                    this.props.setCountDownMessage,
                    this.props.setReportResponseDelay,
                    this.sendLogToBackend,
                    this.captureTheSentMsgViaBot,
                    this.props.setSentMsgId,
                    this.props.lastResponseFromBackend,
                    this.props.setLastResponseFromBackend,
                    this.props.updateCountDownTime,
                    this.state.ws_connection_status,
                    this.setWebsocketTryingToReconnectStatus
                );
                this.setupInterval(this.state.timeInterval);
            }
        }

        pingWebSocket = () => {
            if (this.state.ws_connection_ref === null) return;
            let headers = { mode: "no-cors" };
            fetch(`${API_URL}/api/v1/ping`, headers)
                .then ((result) => {
                    if (this.state.internet_connection_status != CONNECTION_STATUS.CONNECTED) {
                        console.log('INTERNET CONNECTION RESTORED : ', result)
                        this.setState({
                            ...this.state,
                            internet_connection_status: CONNECTION_STATUS.CONNECTED,
                            internet_connection_status_retries: 0
                        });
                    }
                    if(this.state.ws_connection_status ===CONNECTION_STATUS.DISCONNECTED){
                        this.state.ws_connection_ref.connect()
                    }
                })
                .catch ((error) => {
                    if (this.state.internet_connection_status != CONNECTION_STATUS.DISCONNECTED) {
                        console.log('ERROR IN INTERNET CONNECTION : ', error)
                        this.setState({
                            ...this.state,
                            internet_connection_status: CONNECTION_STATUS.DISCONNECTED,
                            internet_connection_status_retries: this.state.internet_connection_status_retries + 1
                        });
                    }
                })
            
            this.state.ws_connection_ref.ping();
        };

        setupInterval(timeout) {
            console.log(
                "Connection manager setting interval:" + timeout + " seconds."
            );
            let interval_id = setInterval(() => {
                this.check_internet_connection();
            }, timeout * 1000);
            this.setState({
                ...this.state,
                check_internet_connection_interval_call_id: interval_id,
                interval_time: timeout,
            });
        }

        check_internet_connection = () => {
            
            this.pingWebSocket()
        };
        
        sendLogToBackend = (data, infoType, source) => {
            //Sending the log to backend
            // var dicData = JSON.parse(data)
            let requestData = {
                ...data,
                source: source,
                log_type: infoType,
            };
            Logger(requestData)
                .then((response) => response.json())
                .catch((error) => {
                    console.log(error);
                });
        };

        captureTheSentMsgViaBot = (msg) => {
            this.props.setTriedToSendMsg(msg);
        };

        render() {
            return (
                <div>
                    <ComposedComponent
                        {...this.props}
                        web_socket={this.state.ws_connection_ref}
                        internet_connection_status={this.state.internet_connection_status}
                        ws_connection_status={this.state.ws_connection_status}
                        internet_connection_status_retries={
                            this.state.internet_connection_status_retries
                        }
                        interval_time={this.state.interval_time}
                        get_error_code={this.get_error_code}
                        makeWebsocketConnection={this.makeConnection}
                    />
                </div>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            triedToSendMsg: state.triedToSendMsg,
            lastResponseFromBackend: state.lastResponseFromBackend,
            conversationId: state.conversationId,
            internetConnectionOfflineTextTop: state?.Reducer?.staticData?.internet_connection_issue?.offline_text_at_the_top,
            internetConnectionConnectingText: state?.Reducer?.staticData?.internet_connection_issue?.connecting_text_at_the_top
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            setWebSocketFunc: (ws) => dispatch(setwebSocket(ws)),
            readyStateFunc: (socketReadyState) =>
                dispatch(socketReadyStateFunc(socketReadyState)),
            setShowCountDown: (data) => dispatch(setShowCountDown(data)),
            setCountDownMessage: (data) => dispatch(setCountDownMessage(data)),
            setReportResponseDelay: (data) => dispatch(setReportResponseDelay(data)),
            setTriedToSendMsg: (msg) => dispatch(setTriedToSendMsg(msg)),
            setSentMsgId: (id) => dispatch(setSentMsgId(id)),
            setLastResponseFromBackend: (response) =>
                dispatch(setLastResponseFromBackend(response)),
            updateCountDownTime: (response) =>
                dispatch(updateCountDownTime(response)),
            setWebsocketTryingToReconnectStatus:(status)=>
             dispatch(setWebsocketTryingToReconnectStatus(status))
            
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(ConnectionManager);
}
