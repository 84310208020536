import React, { Fragment, useState, useEffect } from "react";
import Calendar from "../Calendar";
import DoctorAvailable from "./DoctorAvailable";
import styles from "./DoctorAvailablity.module.css";
import doctorList from "./dummyAvailableDoctor";
import moment from "moment-timezone";
import { timeConvert } from '../../../Methods/TimeConversion'
import TimeSlot from "./TimeSlot";

const DoctorAvailablity = (props) => {
  const [morningSlots, setMorningSlots] = useState([]);
  const [afterNoonSlots, setAfterNoonSlots] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlotDateTime, setSelectedSlotDateTime] = useState("");
  const [isProceedToAppointment, setIsProceedToAppointment] = useState(false);
  const [isChooseADifferentDay, setIsChooseADifferentDay] = useState(false);
  const [morningText, setMorningText] = useState("")
  const [noonText, setNoonText] = useState("")

  const [buttonsData, setButtonsData] = useState([
    { button_name: "Morning", id: "morning" },
    { button_name: "Afternoon", id: "afternoon" },
  ]);
  const [selectedDaynoon, setSelectedDaynoon] = useState("");
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const getShortMonthName = function (date) {
    return monthNames[date.getMonth()].substring(0, 3);
  }
  // console.log("selectedDaynoon", selectedDaynoon);

  // console.log("morningSlots", morningSlots);
  // console.log("afterNoonSlots", afterNoonSlots);

  //Filtering the time slots for morning and after noon
  useState(() => {
    if (props.doctorAvailablityData) {
      if (props.doctorAvailablityData.slots_in_time_ranges) {
        let morningSlots=[]
        let eveningSlots=[]
        props.doctorAvailablityData.slots_in_time_ranges.forEach(
          (item, index) => {
            if(index===0){

              setMorningText(item.time_of_day)
              morningSlots=[...morningSlots, ...item.slots]
            }else {
              setNoonText(item.time_of_day)
              eveningSlots=[...eveningSlots,...item.slots ]

            }

            // if (item.start_time === "06:00 AM") {
            //   morningSlots=[...morningSlots,...item.slots ]
            //   // setMorningSlots((prevState) => [...prevState, ...item.slots]);
            // } else if (item.start_time === "10:00 AM") {
            //   morningSlots=[...morningSlots,...item.slots ]

            //   // setMorningSlots((prevState) => [...prevState, ...item.slots]);
            // } else if (item.start_time === "12:00 PM") {
            //   eveningSlots=[...eveningSlots,...item.slots ]
              
            //   // setAfterNoonSlots((prevState) => [...prevState, ...item.slots]);
            // } else if (item.start_time === "02:00 PM") {
            //   eveningSlots=[...eveningSlots,...item.slots ]

            //   // setAfterNoonSlots((prevState) => [...prevState, ...item.slots]);
            // } else if (item.start_time === "04:00 PM") {
            //   eveningSlots=[...eveningSlots,...item.slots ]

            //   // setAfterNoonSlots((prevState) => [...prevState, ...item.slots]);
            // }
          }
        );
      setMorningSlots((prevState)=>[...prevState, ...morningSlots])
      setAfterNoonSlots((prevState)=>[...prevState, ...eveningSlots])

      if(morningSlots.length >0 && eveningSlots.length===0){
        setSelectedDaynoon("morning")
      }else if (morningSlots.length ===0 && eveningSlots.length>0){
        setSelectedDaynoon("afternoon")

      }else if(morningSlots.length >0 && eveningSlots.length>0){
        setSelectedDaynoon("morning")
      }else{
        setSelectedDaynoon("morning")

      }

      }
    }
  }, [props.doctorAvailablityData]);

  //On fliping the noon reseting the selecting field
  useEffect(() => {
    setSelectedSlotDateTime(() => "");
    setIsProceedToAppointment(() => false);
    setSelectedDoctorId(() => "");
    setSelectedSlot(() => "");
  }, [selectedDaynoon]);

  useEffect(() => {
    props.scrollIntoView();
  });

  const handleAIResponse = (webSocketMessage) => {
    let ai_response = JSON.parse(webSocketMessage.data);
    props.actionProvider.showResponseDialog(ai_response);
  };

  useEffect(() => {
    if (isProceedToAppointment) {
      // let dateTime = moment(selectedSlotDateTime, "YYYY-MM-DD hh:mm");
      // console.log("SendingDate", moment(dateTime).format("DD-MM-YYYY hh:mm:ss"));
      // console.log(dateTime)
      let splitedDateNtime = selectedSlotDateTime.split(" ")
      let splitDate = splitedDateNtime[0].split("-")
      let splitTime = splitedDateNtime[1].split(":")
      let time = `${splitTime[0]}:${splitTime[1]}`


      props.actionProvider.showResponseDialog({
        type: "USER_TEXT",
        message: `${props.selected_time_text}: ${splitDate[2]}-${monthNames[splitDate[1] - 1]}-${splitDate[0]} ${timeConvert(time)}  `,
      });

      let message = {
        event: "CONFIRM_SLOT_GP",
        uid: props.conversationId,
        data: "Proceed",
        slot_time: selectedSlotDateTime,
        doctor_pms_id: selectedDoctorId,
      };

      props.wsClient.handleUserResponse(message, handleAIResponse);
    }
  }, [isProceedToAppointment]);
  const chooseAdifferentDay = () => {
    // console.log("SendingDate", moment(dateTime).format("DD-MM-YYYY hh:mm:ss"));

    props.actionProvider.showResponseDialog({
      type: "USER_TEXT",
      message: `Choose a different day`,
    });

    let message = {
      event: "DENY_SLOT_GP",
      uid: props.conversationId,
      data: "Choose a different day",
      slot_time: null,
    };

    props.wsClient.handleUserResponse(message, handleAIResponse);
    setIsChooseADifferentDay(true);
  

  };

  const showTimes = () => {
    if (isProceedToAppointment) return false;
    else if (isChooseADifferentDay) {
      return false;
    } else if (!isProceedToAppointment) return true;
  };

  const DoctorAvailableData = (props) => {
    // console.log("slotInTimeRange", props.slotInTimeRange);
    return (
      <Fragment>
        <DoctorAvailable
          // doctorName={props.doctorName}
          slotInTimeRange={props.slotInTimeRange}
          // doctorId={props.doctorId}
          setAvailableSlots={(val) => setAvailableSlots(val)}
          setSelectedDoctorId={(val) => setSelectedDoctorId(val)}
          selectedDoctorId={selectedDoctorId}
          setSelectedTimeRange={(val) => setSelectedTimeRange(val)}
          selectedTimeRange={selectedTimeRange}
          setSelectedSlot={(val) => setSelectedSlot("")}
          setSelectedSlotDateTime={(val) => setSelectedSlotDateTime(val)}
          disabledTimeRange={isProceedToAppointment}
        />
        {showTimes() && selectedTimeRange && (
          <TimeSlot
            availableSlots={availableSlots}
            setSelectedSlot={(val) => setSelectedSlot(val)}
            selectedSlot={selectedSlot}
            // doctorId={props.doctorId}
            selectedDoctorId={selectedDoctorId}
            setSelectedSlotDateTime={(val) => setSelectedSlotDateTime(val)}
            setIsProceedToAppointment={(val) => setIsProceedToAppointment(val)}
            setSelectedDoctorId={(val) => setSelectedDoctorId(val)}
            chooseAdifferentDay={chooseAdifferentDay}
          />
        )}
      </Fragment>
    );
  };

  const MorningAndNoonslots = () => {
    return (
      <div className={styles.morningAndNoonslots}>
        <div className={styles.morningAndNoonslots__buttons}>
        <div className={styles.tab_bar} >
          <div className={styles.morning_tab}
          onClick={() => setSelectedDaynoon("morning")}
          style={{
            backgroundColor: "morning" === selectedDaynoon && "rgba(17, 159, 218, 1)",
            // border:"morning" === selectedDaynoon && "2px solid blue",
            color: "morning" === selectedDaynoon && "white",
            fontWeight: "morning" === selectedDaynoon && "bold",



            
          }}
          >{morningText}</div>
          <div className={styles.afternoon_tab}
          onClick={() => setSelectedDaynoon("afternoon")}
          
          style={{
            backgroundColor: "afternoon" === selectedDaynoon && "rgba(17, 159, 218, 1)",
            color: "afternoon" === selectedDaynoon && "white",
            fontWeight: "afternoon" === selectedDaynoon && "bold",

            // borderTop: "afternoon" === selectedDaynoon && " 1px solid #119FDA;"
            
          }}
          >{noonText}</div>
          </div>
          <div></div>

         
        </div>
        {selectedDaynoon === "morning" ? (
          <TimeSlot
            availableSlots={morningSlots}
            setSelectedSlot={(val) => setSelectedSlot(val)}
            selectedSlot={selectedSlot}
            // doctorId={props.doctorId}
            selectedDoctorId={selectedDoctorId}
            setSelectedSlotDateTime={(val) => setSelectedSlotDateTime(val)}
            setIsProceedToAppointment={(val) => setIsProceedToAppointment(val)}
            setSelectedDoctorId={(val) => setSelectedDoctorId(val)}
            chooseAdifferentDay={chooseAdifferentDay}
            timeAmOrPm="AM"
            confirm_selection={props.confirm_selection}
            deny_selection={props.deny_selection}

          />
        ) : (
          <TimeSlot
            availableSlots={afterNoonSlots}
            setSelectedSlot={(val) => setSelectedSlot(val)}
            selectedSlot={selectedSlot}
            // doctorId={props.doctorId}
            selectedDoctorId={selectedDoctorId}
            setSelectedSlotDateTime={(val) => setSelectedSlotDateTime(val)}
            setIsProceedToAppointment={(val) => setIsProceedToAppointment(val)}
            setSelectedDoctorId={(val) => setSelectedDoctorId(val)}
            chooseAdifferentDay={chooseAdifferentDay}
            timeAmOrPm="PM"
            confirm_selection={props.confirm_selection}
            deny_selection={props.deny_selection}

          />
        )}
      </div>
    )
    
    ;
  };
  return (
    <div
      className={styles.doctorAvailablity}
      style={{ display: (isProceedToAppointment ||isChooseADifferentDay) && "none" }}
    >
      {/*{props.doctorAvailablityData &&
        props.doctorAvailablityData != undefined && (
          <DoctorAvailableData
            slotInTimeRange={props.doctorAvailablityData.slots_in_time_ranges}
          />
        )}*/}

      {((morningSlots && morningSlots.length > 0) ||
        (afterNoonSlots && afterNoonSlots.length)) && <MorningAndNoonslots />}
    </div>
  );
};

export default DoctorAvailablity;
